import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom'
import outlook from '../../assests/images/outlook.png'
import gmail from '../../assests/images/gmail.png'
import  apiUrl from '../../utils/apiUrl'

import  { 
  NewsletterConfirmTitle,
  NewsletterConfirmText,
  NewsletterConfirmWrapper,
  NewsletterConfirmInfoWrapper,
  NewsletterConfirmInfoText,
  MailsWrapper,
  MailsItem,
  MailIcon,
  MailText,
  MailSuccessInfo,
  MailSuccessTitle,
  MailsGoHome
} from './NewsletterConfirm.styles'

const NewsletterConfirm = () => {
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasUpdated, setHasUpdated] = useState(false)
  const location = useLocation()
  
  const email = location.search.split('email=')[1]

  useEffect(() => {
    const updateEmailStatus = async () => {
      if (!email) {
        setHasError(true)
        return
      }
        
      setHasError(false)
      setIsLoading(true)

      await fetch(
        `${apiUrl}/api/web/newsletter/confirm`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email })
        },
      )
      .then(res => res.json())
      .then(res => {
        if (res.status === 'OK') {
          setHasUpdated(true)
        } else {
          setHasError(true)
          console.error('Error. Newsletter Confirm: ', res.message)
        }
      })
        .catch(error => {
          setHasError(true)
          console.error('Error. Newsletter Confirm: ', error)
        })
      .finally(() => {
        setIsLoading(false)
      })
    }
    updateEmailStatus()
  }, [])

  if (hasError) {
    return (
       <NewsletterConfirmWrapper>
        <NewsletterConfirmInfoText>No se ha podido confirmar tu correo</NewsletterConfirmInfoText>
        <NewsletterConfirmText>Inténtelo otra vez</NewsletterConfirmText>
      </NewsletterConfirmWrapper>
    )
  }
  
  if (isLoading || !hasUpdated) {
    return (
      <NewsletterConfirmWrapper>
        <NewsletterConfirmInfoText>Loading...</NewsletterConfirmInfoText>
      </NewsletterConfirmWrapper>
    )
  }
    

  return (
    <NewsletterConfirmWrapper>
      <NewsletterConfirmTitle>Bienvenido viajero/a,</NewsletterConfirmTitle>
    <NewsletterConfirmInfoWrapper>
      <NewsletterConfirmInfoText>Mira tu correo, te hemos enviado el audio secreto de La Pregunta 🎁</NewsletterConfirmInfoText>
    </NewsletterConfirmInfoWrapper>
      <MailSuccessTitle>Comprueba tu correo electrónico</MailSuccessTitle>
      <MailsWrapper>
        <MailsItem>
          <a href='https://mail.google.com/mail/u/0/#inbox' target='_blank' rel='noopener noreferrer'>
            <MailIcon src={gmail} alt='gmail icon' />
            <MailText>Abrir Gmail</MailText>
          </a>
        </MailsItem>
        
        <MailsItem>
          <a href='https://outlook.live.com/mail/0/inbox' target='_blank' rel='noopener noreferrer'>
            <MailIcon src={outlook} alt='outlook icon' />
            <MailText>Abrir Outlook</MailText>
          </a>
        </MailsItem>
      </MailsWrapper>
      <MailSuccessInfo>¿No has recibido el correo electrónico? Comprueba la carpeta de correo no deseado.</MailSuccessInfo>
    </NewsletterConfirmWrapper>
  )
}

export default NewsletterConfirm;