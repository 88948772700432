import styled from 'styled-components';

const grey = '#c7c7c7'
const greyDark = '#444'

export const SocialNetworksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SocialNetworksList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
`

export const SocialNetworksListItem = styled.li`
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 7px;
  cursor: pointer;
  opacity: 1;
  background: ${({ isLight }) => isLight ? greyDark : '#fff'};
  transition: .3s;
  a {
    color: #fff;
  }
  &:hover {
    background: ${grey};
  }
`

export const SocialNetworksIcon = styled.img`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15px;
  height: 15px;
  padding: 10px 0;
`

export const SocialNetworksIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
`