import apiUrl from '../../utils/apiUrl'

const BASE_PATH = '/api/blog/admin/clouds'

export const getCloudImages = async (folder) => {
  const token = localStorage.getItem('token')
  return await fetch(`${apiUrl}${BASE_PATH}?folder=${folder}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
}

export const getCloudFolders = async () => {
  const token = localStorage.getItem('token')
  return await fetch(`${apiUrl}/api/blog/admin/folders/list`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
}

export const createFolder = async (folderName) => {
  const token = localStorage.getItem('token')
  return await fetch(`${apiUrl}/api/blog/admin/folders/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ folderName })
    })
      .then(res => res.json())
}

export const deletePost = async (postID) => {
  const token = localStorage.getItem('token')
  return await fetch(`${apiUrl}/api/blog/admin/delete/${postID}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .catch(err => console.log(err));
};

export const deleteCloudImage = async (folderName, fileName) => {
  const token = localStorage.getItem('token')
  return await fetch(`${apiUrl}/api/blog/admin/clouds?folderName=${folderName}&fileName=${fileName}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .catch(err => console.log(err));
};
