import styled from 'styled-components';

export const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const LoginInputWrapper = styled.div`
  margin: 20px;
  width: 350px;
  height: 100px;
`

export const LoginInput = styled.input`
  padding: 30px 20px;
  width: 250px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 5px 5px 5px rgba(0,0,0, .2);
  border: 1px solid #333;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  &:focus {
    padding: 27px 17px;
    border: 3px solid orange
  }
`

export const Title = styled.h1`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 42px;
  font-weight: 700;
`