import React, { useState } from 'react';
import apiUrl from '../../utils/apiUrl'
import outlook from '../../assests/images/outlook.png'
import gmail from '../../assests/images/gmail.png'
import { useTranslation, Trans } from 'react-i18next';

import {
  ModalWrapper,
  ModalContent,
  ModalContentRight,
  ModalContentLeft,
  ModalCloseIconWrapper,
  ModalTitle,
  ModalDescription,
  ModalInput,
  ModalButton,
  ModalErrorHint,
  ModalHint,
  TextBold,
  MailIcon,
  MailsWrapper,
  MailText,
  MailsItem,
  ModalSuccessTitle,
  ModalSuccessText,
  ModalSuccessInfo,
  ModalHintEmail,
  ModalHintName,
  ModalFormWrapper,
  ModalFormTitle,
  ModalTitleSpecial,
  Colored,
  Underline
} from './SellLetterLaCarta.styles'

const SellLetterLaCarta = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessMessage, setIsSuccessMessage] = useState(false)
  
  const { t } = useTranslation()

  const onSubmit = () => {
    const validateEmail = email => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    const isEmailValid = validateEmail(email)

    if (!isEmailValid) {
      setError(t('newsletter.succeed.email'))
      return
    }

    setIsLoading(true)
    
    fetch(
      `${apiUrl}/api/web/newsletter/create`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      },
    )
    .then(res => res.json())
    .then(res => {
      if (res.status === 'OK') {
        setIsSuccessMessage(true)
        setEmail(email)
      } else {
        console.log(res.message)
        setError(res.message)
        console.error('Error. Newsletter: ', res.message)
      }
    })
      .catch(error => {
        setError(t('newsletter.error.tryAgain'))
        console.error('Error. Newsletter: ', error)
      })
    .finally(() => {
      setIsLoading(false)
    })
  }
  

  return (
      <ModalWrapper>
        <ModalContent>
          <ModalContentLeft />
          <ModalContentRight>
            {isSuccessMessage ? (
              <>
                <ModalSuccessTitle>{t('newsletter.succeed.title')}</ModalSuccessTitle>
                <ModalSuccessText>
                  <Trans i18nKey="newsletter.succeed.confirmMessage" components={{ bold: <b />, email }}/>
                </ModalSuccessText>
                <MailsWrapper>
                  <MailsItem>
                    <a href='https://mail.google.com/mail/u/0/#inbox' target='_blank' rel='noopener noreferrer'>
                      <MailIcon src={gmail} alt='gmail icon' />
                      <MailText>{t('newsletter.succeed.open')} Gmail</MailText>
                    </a>
                  </MailsItem>
                  
                  <MailsItem>
                    <a href='https://outlook.live.com/mail/0/inbox' target='_blank' rel='noopener noreferrer'>
                      <MailIcon src={outlook} alt='outlook icon' />
                      <MailText>{t('newsletter.succeed.open')} Outlook</MailText>
                    </a>
                  </MailsItem>
                </MailsWrapper>
                <ModalSuccessInfo>{t('newsletter.succeed.checkSpam')}</ModalSuccessInfo>
              </>
            ) : (
              <>
                <ModalTitle>{t('newsletter.title')}</ModalTitle>
                <ModalTitleSpecial>{t('newsletter.laCarta')}</ModalTitleSpecial>
                <ModalDescription>
                  <Trans i18nKey="newsletter.knowStories" components={{ bold: <Underline /> }}/>
                </ModalDescription>
                <ModalDescription>
                  <Trans i18nKey="newsletter.leadMagnet" components={{ special: <Underline /> }}/>
                </ModalDescription>
                <ModalFormWrapper>
                  <ModalFormTitle>
                    <Trans i18nKey="newsletter.sendEmailAndName" components={{ special: <Colored /> }}/>
                  </ModalFormTitle>
                  <ModalInput placeholder={t('newsletter.email')} value={email} onChange={(evt) => {setEmail(evt.target.value)}} />
                  <ModalHintEmail>{t('newsletter.emailHint')}</ModalHintEmail>
                  {error && <ModalErrorHint>{error}</ModalErrorHint>}
                  <ModalButton onClick={onSubmit}>{isLoading ? t('newsletter.buttonTextSending') : t('newsletter.buttonText')}</ModalButton>
                </ModalFormWrapper>
              </>
            )}
          </ModalContentRight>
        </ModalContent>
      </ModalWrapper>
  ); 
}

export default SellLetterLaCarta;