import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/header/Header';
import NewsletterBox from '../../components/NewsletterBox/NewsletterBox'
import erpLogo from '../../assests/images/logo-erp.png';
// import audiom from './C0060.MP4'

import  {
  AboutContent, 
  AboutWrapper, 
  AboutText,
  AboutTitle,
  AboutHeader,
  AboutPerson,
  AboutPersonVideo,
  AboutPersonDescriptionWrapper,
  AboutPersonDescription,
  AboutHeaderTitle,
  AboutPersonImg,
  AboutPersonImgWrapper,
  AboutButtonWrapper,
  AboutHeaderText,
  AboutSocialNetworks,
  SocialNetworksIcon,
  AboutSocialNetworksText,
  AboutBrand,
  AboutHeaderTextBold,
  AboutBrandWrapper,
  AboutContentText,
  LogoImage,
  LeadMagnetAudio
} from './LeadMagnet.styles'

const About = () => {
  const history = useHistory()
  const [controlVideo, setControlVideo] = useState('')

  const { t } = useTranslation()

  const handleClickVideo = (name) => {
    setControlVideo(name)
  }
  
  return (
    <AboutWrapper>
     
      {/* <Header
        imageUrl={imageUrl}
        title='Lorem ipsum dolor sit amet.'
      /> */}
      <AboutHeader>
        {/* <AboutHeaderTitle>¿Por qué?</AboutHeaderTitle> */}
        <AboutHeaderText>
         La pregunta que siempre nos hacen y, por supuesto, la respuesta a ella.
        </AboutHeaderText>
      </AboutHeader> 
      <AboutContent>
        {/* <AboutContentText>
          La pregunta
        </AboutContentText> */}

        <LeadMagnetAudio controls src="https://cdn-empty-road.s3.eu-west-1.amazonaws.com/pregunta-leadMagnet.mov"></LeadMagnetAudio>
        {/* <LogoImage src={erpLogo} alt='Logo Empty Road Project' /> */}
      </AboutContent>
    </AboutWrapper>
  );
};

export default About;