import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiUrl from '../../../utils/apiUrl'
import Header from "../../../components/header/Header";
import Feedback from "../../../components/Feedback/Feedback";
import NewsletterBox from "../../../components/NewsletterBox/NewsletterBox";
import zenModeBlack from "../../../assests/images/zen-mode-black.png";
import zenModeWhite from "../../../assests/images/zen-mode-white.png";
import goTopIcon from "../../../assests/images/goTopIcon.png";
import PhotoRender from "../../../components/PhotoRender/PhotoRender"

import {
  AdventureWrapper,
  AdventureArticule,
  AdventureTitle,
  AdventureCountry,
  AdventureContent,
  ZenModeButtonWrapper,
  ZenModeButton,
  ZenModeImage,
  ZenModeButtonText,
  GoToTopButtonWrapper,
  GoToTopButton,
  GoToTopImage
} from './StoryBlogView.style'

const AdventuresView = ({ zenMode, setZenMode,  isScrollTop }) => {
  const [adventure, setAdventure] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { slug } = useParams()
  
  useEffect(() => {
    let isCurrent = true
    setIsLoading(true)
    fetch(`${apiUrl}/api/web/blog/view/${slug}`)
      .then(res => res.json())
      .then(result => {
        if (result.status !== 200) {
          console.error(result.error)
        }
        isCurrent && setAdventure(result.data)
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
    return ()=> isCurrent = false
  }, [slug])

  const {
    content,
    country,
    mainImage = {},
    title,
    isMagazine, 
    images
  } = adventure

  return (
    <AdventureWrapper>
      {!adventure._id && isLoading
        ? 'Loading...'
        : (
          <>
            {/* {!zenMode && ( */}
              <Header
                imageSrc={mainImage?.src}
                isLessHeigh
              />
            {/* )} */}
            <AdventureArticule>
              {/* <ZenModeButtonWrapper>
                <ZenModeButton title={`${!zenMode ? 'Activar' : 'Desactivar' } Zen Mode`} zenMode={zenMode} onClick={() => setZenMode((prevState) => !prevState)}>
                  <ZenModeImage src={!zenMode ? zenModeBlack : zenModeWhite} alt='Zen mode icon' />
                </ZenModeButton>
                <ZenModeButtonText>
                  {`Zen Mode ${!zenMode ? 'ON' : 'OFF' }`}
                </ZenModeButtonText>
              </ZenModeButtonWrapper> */}
              {!isScrollTop && (
                <GoToTopButtonWrapper>
                  <GoToTopButton title='Go to top' onClick={() => window.scrollTo(0, 0)}>
                    <GoToTopImage src={goTopIcon} alt='Go to top icon' />
                  </GoToTopButton>
                </GoToTopButtonWrapper>
              )}
              <AdventureCountry>
                {country}
              </AdventureCountry>
              <AdventureTitle>
                {title}
              </AdventureTitle>
              {
                isMagazine ? (
                  <PhotoRender images={images} />
                ) : (
                  <AdventureContent
                    dangerouslySetInnerHTML={{ 
                      __html: content 
                    }}
                  />
                )
              }
              <Feedback postId={adventure._id}/>
            </AdventureArticule>
          </>
        )}
      {adventure._id && !isLoading && <NewsletterBox />}
    </AdventureWrapper>
  );
};

export default AdventuresView;