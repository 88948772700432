import React from 'react';

import {
  MobileNavToggler,
  MobileNavTogglerWrapper,
  Inner
} from './MobileNavToggle.styles'

const MobileNavToggle = ({ setActive, isActive, dark }) => {
  return (
    <MobileNavTogglerWrapper onClick={() => setActive(!isActive)}>
      <MobileNavToggler>
        <Inner isActive={isActive} dark={dark} />
      </MobileNavToggler>
    </MobileNavTogglerWrapper>
  );
};

export default MobileNavToggle;