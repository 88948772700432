import React, { useState } from 'react'

import apiUrl from '../../utils/apiUrl'
import { getCloudImages } from '../../utils/api/blog'
import { copyToClipboard  } from '../../utils/helpers'
import copyIcon from './copy-icon.jpeg'
import editIcon from './edit-icon.png'

import {
  Wrapper,
  ImageCheckboxWrapper,
  ImageBoxWrapper,
  ImageWrapper,
  FolderSelectorWrapper,
  ImagesManagerWrapper,
  ImagePositionNumWrapper,
  SelectedInfo,
  ImageCopy,
  ImageEdit,
  EmptyStateMessage,
  EmptyStateButton,
  EmptyStateWrapper,
  ActionWrapper
} from './ImagesManager.styles';

const ImagesManager = ({ 
  images= [], 
  onSelectImage = () => { },
  cloud_folder,
  setDescriptionModal
}) => {
  const [imagesCloud, setImagesCloud] = useState([])
  const [isFetching, setFetching] = useState(false)

  const getImageFromCloud = async () => {
    console.log('isFetching', isFetching)
    console.log('cloud_folder', cloud_folder)
    if (isFetching || !cloud_folder) return
    setFetching(true)
    await getCloudImages(cloud_folder)
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error getting cloud images')
        }
        console.log('data', data)
        setImagesCloud(data)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setFetching(false)
      })
  }

  const selectedInfo = !!imagesCloud.length && (
    `Seleccionadas ${images.length} de ${imagesCloud.length}`
  ) 

  const onCopyHandler = (evt, src) => {
    evt.stopPropagation()
    copyToClipboard(src)
  }
  
  const onEdityHandler = (evt, data) => {
    evt.stopPropagation()
    setDescriptionModal(data)
  }
  
  return (
    <ImagesManagerWrapper>
      {(imagesCloud || []).length > 0 && <button onClick={getImageFromCloud}>Bajar imágenes nube</button>}
      <SelectedInfo>{selectedInfo}</SelectedInfo>
      <Wrapper>
        {(imagesCloud || []).length > 0 ? (
          imagesCloud.map((src, idx) => {
            const indexFound = images.findIndex(e => e.url === src) 
            const imgFound = images.find(e => e.url === src) 
            const isSelected = indexFound >= 0
            const data = {
              url: src,
              indexFound: indexFound + 1,
              description: imgFound?.description || ''
            }
            
            const isVideo = src.includes('video') || src.includes('.mov')

            return (
              <ImageBoxWrapper
                key={src}
                isSelected={isSelected}
                onClick={() => onSelectImage(isSelected, src)}
              >
                <ImageWrapper isSelected={isSelected}>
                  {
                    isVideo 
                      ? <video width='100%' controls src={src} />
                      : <img style={{ width: '100%' }} src={src} />
                  }
                </ImageWrapper>
                {isSelected && (
                  <ImagePositionNumWrapper>
                    {indexFound + 1}
                  </ImagePositionNumWrapper>
                )}
                <ActionWrapper>
                  <ImageCopy onClick={(evt) => onCopyHandler(evt, src)}>
                    <img src={copyIcon} alt='Copy icon' />
                  </ImageCopy>
                  <ImageEdit
                    disable={!isSelected}
                    hasDescription={!!imgFound?.description}
                    onClick={(evt) => isSelected && onEdityHandler(evt, data)}
                  >
                    <img src={editIcon} alt='edit icon' />
                  </ImageEdit>
                </ActionWrapper>
              </ImageBoxWrapper>
            );
          })
        ) : (
          <EmptyStateWrapper>
            <EmptyStateMessage>Haz click en "Bajar imágenes nube" para cargar las imágenes de la nube</EmptyStateMessage>
              <EmptyStateButton onClick={getImageFromCloud}>
                Bajar imágenes nube
            </EmptyStateButton>
          </EmptyStateWrapper>
        )}
      </Wrapper>
      <SelectedInfo>{selectedInfo}</SelectedInfo>
    </ImagesManagerWrapper>
  );
};

export default ImagesManager;