import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const AdventureArticuleBox = styled.article`
`

export const AdventureArticuleText = styled.p`
  font-size: 14px;
  font-weight: 100;
  padding: 0 20px;
  margin-bottom: 20px;
  line-height: 15px;
  text-align: left;
`

export const AdventureText = styled.p`
  font-weight: 600;
  font-size: 18px;
  // text-decoration: underline;
  color: #830390;
`
export const AdventureCallWrapper = styled.div`
  display: none
`
export const AdventureCallText = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #830390;
`

export const AdventureArticuleThumbnailWrapper = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 15px;
  height: 350px;
  transition: .3s;
`

export const AdventureButtonWrapper = styled.div`
  display: none;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  
  /* background: #ddd; */
`
export const AdventureArticuleThumbnail = styled.div`
  background-image: ${({ src }) => src && `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
`

export const AdventureArticuleDecorator = styled.div`
  width: 60px;
  height: 2px;
  border-radius: 5px;
  background-color: #830390;
  margin: 15px auto ;
`

export const AdventureArticuleWrapper = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 510px;
  padding-bottom: 30px;
  transition: 1s;
  ${({ disabled }) => disabled ? `
    opacity: .3
  ` : `
    cursor: pointer;
  `}
  &:hover ${AdventureArticuleText} {
    display: none;
  }
  &:hover ${AdventureCallWrapper} {
    display: flex;
    align-items: center;
    gap: 10px
  }
   &:hover ${AdventureArticuleBox} {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 0;
    padding: 30px 20px 10px;
    background: #fff;
    border-radius: 4px;
  }

   &:hover ${AdventureArticuleThumbnail} {
    opacity: .2
  }
  &:hover ${AdventureButtonWrapper} {
    display: flex; 
    margin-top: 80px
  }
  &:hover ${AdventureArticuleDecorator} {
    margin-left: 0;
  }
`

export const AdventureArticuleCountry = styled.span`
  color: #830390;
  display: inline-block;
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
`

export const AdventureArticuleTitle = styled.h2`
  color: #313131;
  font-size: 22px;
  font-weight: 300;

  font-family: 'Averia Serif Libre', sans-serif;
`