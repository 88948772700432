import styled from 'styled-components';

export const DropAreasWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
`

export const DropAreaWrapper = styled.div` `


export const DropAreaBox = styled.div`
  height: 300px;
  width: 300px;
  border: 1px dashed;
  margin-top: 10px;
  &:hover {
    background-color: #d0efff;
    border-color: blue;
    cursor: pointer
  }
`
export const DropAreaInput = styled.input`
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  /* visibility: hidden; */
`
export const ButtonWrapper = styled.div`
  margin-top: 30px;

`

export const DropAreaList = styled.ul`
  margin-top: 10px;
`

export const DropAreaItem = styled.li`
  font-size: 11px;
  margin: 5px;
  button {
    font-size: 10px;
    margin-left: 10px;
    cursor: pointer;
  }
`

export const DropAreaContent = styled.div`
  display: flex;
`