import styled from 'styled-components';

export const PostOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const PostOptionsGroup = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  gap: 100px;
  justify-content: space-between;
`

export const PostOptionsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 5px;
  width: 100%;
`

export const PostOptionsLabel = styled.label`
  font-size: 12px;
  margin-bottom: 3px;
`
export const PostOptionsInput = styled.input`
  padding: 4px;
`

export const PostOptionsSelect = styled.select`
  width: 100%;
  padding: 4px;
  /* margin: 15px; */
`

export const PostOptionSelectItem = styled.option`

`

export const PostOptionsImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 350px;
  height: 80px;
  overflow: hidden;
`

export const PostOptionsImage = styled.img`
  position: absolute;
  width: 100%;
`

export const PostOptionsSmallImage = styled.img`
  height: 100px;
`
