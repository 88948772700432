import React, { useState, useEffect } from 'react';
import { Link }     from 'react-router-dom'
import PostList     from '../components/blogManager/PostList/PostList'
import Button from '../../../components/button/Button'
import { getCloudFolders, createFolder } from '../../../utils/api/blog'

import {
  CloudManagerListWrapper,
  CloudManagerListCard,
  CloudManagerListCardWrapper,
  CloudManagerListButtonWrapper,
  CloudManagerListNav
} from './CloudManagerList.styles.js'

const CloudManagerList = () => {
  const [folders, setFolders] = useState([])
  const [folder, setFolder] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [newFolderName, setNewFolderName] = useState('')
  
  useEffect(() => {
    if (isLoading) return
    setLoading(true);
    (async () => await getCloudFolders(folder)
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error getting cloud images')
        }
        setFolders(data)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
    )()
  }, [])
  
  const addFolder = (evt) => {
    if (!newFolderName) return
    const name = newFolderName.trim().replace(' ', '-').replace('_', '-').toLowerCase()
    

    setLoading(true);
    (async () => await createFolder(name)
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error creating cloud images')
        }
        const folderClone = [...folders]
        folderClone.push({ name })
        setFolders(folderClone)
        setNewFolderName('')
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
    )()
  }
  const handleChange = (evt) => {
    const name = evt.target.value
    setNewFolderName(name)
  }

  return (
    <CloudManagerListWrapper>
      <CloudManagerListNav>
        <Link to='/admin'>Go to blog manager</Link>
      </CloudManagerListNav>
      <CloudManagerListButtonWrapper>
        <input
          id='newFolderName'
          margin='normal'
          placeholder='new Folder'
          type='newFolderName'
          value={newFolderName || ''}
          onChange={handleChange}
        />
        <button type='button' onClick={addFolder}>
          Create new folder
        </button>
      </CloudManagerListButtonWrapper>
      <CloudManagerListCardWrapper>
        {folders.map(({ name }) => (
          <Link to={`/admin/cloud/${name}`}>
            <CloudManagerListCard key={name} value={name}>
              {name}
            </CloudManagerListCard>
          </Link>
        ))}
      </CloudManagerListCardWrapper>
    </CloudManagerListWrapper>
  );
}

export default CloudManagerList;