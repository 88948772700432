import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/header/Header';
import NewsletterBox from '../../components/NewsletterBox/NewsletterBox'

import { photo, blogName } from '../../constants'

import karo from './karo.jpg'
import rodri from './rodri.jpg'
import insta_icon from './insta_icon.png'

import  {
  AboutContent, 
  AboutWrapper, 
  AboutText,
  AboutTitle,
  AboutHeader,
  AboutPerson,
  AboutPersonVideo,
  AboutPersonDescriptionWrapper,
  AboutPersonDescription,
  AboutHeaderTitle,
  AboutPersonImg,
  AboutPersonImgWrapper,
  AboutButtonWrapper,
  AboutHeaderText,
  AboutSocialNetworks,
  SocialNetworksIcon,
  AboutSocialNetworksText,
  AboutBrand,
  AboutHeaderTextBold,
  AboutBrandWrapper
} from './About.styles'

const About = () => {
  const history = useHistory()
  const [controlVideo, setControlVideo] = useState('')

  const { t } = useTranslation()

  const handleClickVideo = (name) => {
    setControlVideo(name)
  }
  
  return (
    <AboutWrapper>
     
      {/* <Header
        imageUrl={imageUrl}
        title='Lorem ipsum dolor sit amet.'
      /> */}
      <AboutHeader>
        {/* <AboutHeaderTitle>¿Por qué?</AboutHeaderTitle> */}
        <AboutHeaderText>
          El viaje comenzó en Quito (Ecuador) con un vuelo transatlántico de Madrid con escala en Bogotá (Colombia).
          Aún recuerdo los primeros paisajes sobre las nubes. Las selva y las montañas nos daban la bienvenida. 
          Era el inicio de la mayor de nuestras aventuras hasta la fecha. Un viaje que nos cambiaría la vida. 
        </AboutHeaderText>
        <AboutHeaderText>
          La idea era comenzar desde Polonia, explorar primero Medio Oriente y luego Asia, dejar para el final el continente Americano.
          Sin embargo, tomamos la decisión de empezar de la forma que hicimos y tomar ritmo de viaje en un lugar con el mismo idioma. 
        </AboutHeaderText>
        <AboutHeaderText>
          <AboutHeaderTextBold>¿Cómo surgió la idea de tal viaje? </AboutHeaderTextBold>
          En 2017 nos embarcamos en un viaje de esos que dejan huella, recorrimos Europa en autostop, de Polonia a España.
          Fue un viaje intenso, con subidas y bajadas. Dormimos en gasolineras, en playas en plena tomenta. 
          Desde entonces nos propusimos hacer un viaje alrededor del planeta por el que volver a casa.
        </AboutHeaderText>
        <AboutBrandWrapper>
           <AboutBrand translate='no'>Rodrigo, Empty Road Project.</AboutBrand>
        </AboutBrandWrapper>
      </AboutHeader> 
      
        
        {/* <AboutPersonVideo onClick={() => handleClickVideo('rodrigo')}>
          <video width="100%" controls={controlVideo === 'rodrigo'} >
            <source src={presetation_rodri} type="video/mp4" />
            {/* <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </AboutPersonVideo> */}
      <AboutContent>
      <AboutPerson>
        <AboutPersonDescriptionWrapper>
          <AboutPersonDescription>
            <AboutTitle>
              Karolina
            </AboutTitle>
            <AboutText>
              {t('about.karo.passion')}
            </AboutText>
            <AboutText>
              {t('about.karo.camara')}
            </AboutText>
            <AboutText>
              {t('about.karo.iWant')}
            </AboutText>
             <AboutText>
              {t('about.karo.inSection')}
              {' '}
              <Link to='photography'>{photo}</Link>
              {' '}
              {t('about.karo.lifeOurTravel')}
            </AboutText>
          </AboutPersonDescription>
        </AboutPersonDescriptionWrapper>
        <AboutPersonImgWrapper>
          <AboutPersonImg src={karo} alt='Karolina' />
        </AboutPersonImgWrapper>
      </AboutPerson>
      <AboutPerson>
        <AboutPersonImgWrapper>
          <AboutPersonImg src={rodri} alt='Rodrigo' />
        </AboutPersonImgWrapper>
        <AboutPersonDescriptionWrapper>
          <AboutPersonDescription>
            <AboutTitle>
              Rodrigo
            </AboutTitle>
            <AboutText>
              {t('about.rodrigo.allStart')}
            </AboutText>
            <AboutText>
              {t('about.rodrigo.createMyAdventure')}
            </AboutText>
            <AboutText>
              {t('about.rodrigo.experienceTraveller')}
            </AboutText>
            <AboutText>
              {t('about.rodrigo.behindAdventure')}
              {' '}
              <Link to='adventures'>{blogName}</Link>
              {' '}
              {t('about.rodrigo.knowDayByDay')}
            </AboutText>
            <AboutText>
              {t('about.rodrigo.pleasureSayHello')}
            </AboutText>
          </AboutPersonDescription>
        </AboutPersonDescriptionWrapper>
      </AboutPerson>
    </AboutContent>
      <NewsletterBox />
    </AboutWrapper>
  );
};

export default About;