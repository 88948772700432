import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const AdventureWrapper = styled.div`
  min-height: 100vh;
`;

export const AdventureArticule = styled.div`
  position: relative;
  margin-top: 50px;
  min-height: 100vh;
`;

export const AdventureTitle = styled.p`
  color: ${greyDark};
  font-size: 2.5rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Averia Serif Libre', sans-serif;
`

export const AdventureCountry = styled.h2`
  color: #830390;
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const ZenModeButtonWrapper = styled.div`
  position: absolute;
  top: -30px;
  right: 20px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .6
  }
`

export const ZenModeButton = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  background-color: ${ ({ zenMode }) => zenMode ? 'black' : '#fff' };
  border: 1px solid #d4d4d4;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  cursor: pointer;
`

export const ZenModeImage = styled.img`
  width: 30px
`

export const ZenModeButtonText = styled.span`
  font-size: 9px;
  margin-top: 2px;
  font-weight: 100
`

export const GoToTopButtonWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: .6
  }
`

export const GoToTopButton = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  cursor: pointer;
`

export const GoToTopImage = styled.img`
  width: 20px
`