
export const AUTHORS =  {
  rodrigo: 'rodrigo',
  karo: 'karo'
}

export const VISIBILITY = {
  draft: 'draft',
  private: 'private',
  public: 'public'
}

export const TYPE = {
  history: 'history',
  photo: 'photo',
}

export const LANG = {
  es: 'es',
  en: 'en',
  pl: 'pl',
  pt: 'pt',
}

export const AUTHORS_OPTIONS =  [
  AUTHORS.rodrigo,
  AUTHORS.karo
]

export const VISIBILITY_OPTIONS = [
  VISIBILITY.draft,
  VISIBILITY.private,
  VISIBILITY.public
]

export const TYPE_OPTIONS = [
  TYPE.history,
  TYPE.photo
]

export const LANG_OPTIONS = [
  LANG.es,
  LANG.pl,
  LANG.pt,
  LANG.en
]

