export const weekdays = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado"
];

export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

export const getNowInfo = ()=> {
  const d = new Date();
  return {
    year: d.getFullYear(),
    minutes: d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
    hours: d.getHours() < 10 ? "0" + d.getHours() : d.getHours(),
    day: d.getDate() < 10 ? "0" + d.getDate() : d.getDate(),
    month: d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1,
    weekday: weekdays[d.getDay()],
    monthName: months[d.getMonth()]
  };
}

export const generateDay = ()=> {
  const date = getNowInfo()
  return `${date.year}-${date.month}-${date.day}T${date.hours}:${date.minutes}`;
};