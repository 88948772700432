import React from 'react';
import {
  SectionBoxWrapper,
  SectionBoxTextWrapper,
  SectionBoxText,
  SectionBoxTextItalic
} from './SectionBox.style';

const SectionBox = ({
  buttonText,
  imageUrl, 
  isBlack,
  isLeft,
  italicText,
  tailText,
  text,
}) => {
  return (
    <SectionBoxWrapper 
      imageUrl={imageUrl}
      isBlack={isBlack}
      isLeft={isLeft}
    >
    <SectionBoxTextWrapper>
      <SectionBoxText>
        {text}
        {italicText && (
          <SectionBoxTextItalic> 
            {' '}{italicText}{' '}
          </SectionBoxTextItalic> 
        )}
        {tailText && tailText}
      </SectionBoxText>
      <SectionBoxText>
        {buttonText}
      </SectionBoxText>
    </SectionBoxTextWrapper>
  </SectionBoxWrapper>
  )
}

export default SectionBox;