import styled from 'styled-components';

export const BlogManagerWrapper = styled.div`
  min-height: 100vh;
  text-align: left;
  margin: 0 50px;
`

export const BlogManagerNav = styled.div`
  display: flex;
  margin-bottom: 30px;
  text-decoration: underline;
  &:hover {
    opacity: .8;
  }
  a {
    margin-right: 20px;
  }
`

export const BlogManagerButtonWrapper = styled.div`
  width: fit-content;
  overflow: hidden;
  margin-bottom: 30px;
`

export const BlogManagerButton = styled.div`
  padding: 15px 18px;
  width: 140px;
  text-align: center;
  color: #fff;
  font-weight: 900;
  border: none;
  border-radius: 4px;
  background: rgba(113, 113, 113, 0.7);
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  
  &:hover {
    background: rgba(113, 113, 113, 0.8);
  }
`
