import React from 'react';

import { HeaderWrapper } from './Header.styles.js';

const Header = ({ 
  isLessHeigh,
  imageSrc,
  isHeighAuto,
  children
}) => {
  
  let height = '100vh'

  if (isLessHeigh) {
    height = '60vh'
  } else if (isHeighAuto) {
    height = 'auto'
  }

  return (
    <HeaderWrapper 
      imageSrc={imageSrc}
      isLessHeigh={isLessHeigh}
      height={height}
    >
      {children}
    </HeaderWrapper>
  );
};

export default Header;