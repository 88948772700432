import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const BannerAppWrapper = styled.div`
  background-color: #fff;
`;
export const BannerAppContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  flex-wrap: wrap;
  @media(max-width: 390px) {
    padding: 50px 0
  }
`;
export const BannerAppDescriptionWrapper = styled.div`
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  position: relative;
  padding-bottom: 50px;
  @media(min-width: 1050px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
export const BannerAppDescription = styled.div`
  margin-top: 100px;  
`;

export const BannerAppTitleWrapper = styled.h3`
  margin-bottom: 30px;
`;
export const BannerAppTitle = styled.span`
  display: block;
  font-weight: 300;
  font-size: 22px;
  color: ${greyDark};
`;
export const BannerAppText = styled.span`
  font-size: 16px;
  /* font-weight: 200; */ 
  line-height: 22px;
  color: ${greyDark};
  max-width: 400px;
  /* width: auto; */
  margin-bottom: 10px;
`;

export const BannerAppSideWrapper = styled.div`
  /* margin-right: 15px;
  padding: 50px 0; */
`;
export const BannerAppImageWrapper = styled.div`
`;

export const BannerAppButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const BannerMobileImage = styled.img`
  max-width: 350px;
  width: 100%;
`
export const BannerAppCreatorWrapper = styled.div`
  margin-bottom: 30px;
`;
export const BannerAppMobileName = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 24px;
  /* font-weight: 300; */
  color: ${greyDark};
  letter-spacing: 0.8px;
`;
export const BannerAppMobileSlogan = styled.p`
  font-size: 28px;
  color: ${greyDark};
  font-weight: 100;
  margin-bottom: 30px;
`;
export const BannerAppMobileDescription = styled.p`
  font-size: 22px;
  font-weight: 300;
  color: ${greyDark};
`;
export const BannerAppMobileText = styled.p`
  font-size: 10px;
  color: ${greyDark};
  margin-top: 8px;
`;
export const BannerAppCreator = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: bold;
`;

export const BannerAppIntroList = styled.p`
  font-size: 28px;
  color: ${greyDark};
`;

export const BannerAppList = styled.ul`
  text-align: left;
  width: 300px;
  margin: 30px auto;
`;
export const BannerAppListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const TravelPhoto = styled.img`
  max-width: 450px;
  width: 100%;
`;

export const BannerAppMobileStoresWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  img {
    opacity: .1;
  }
`;

export const BannerAppTextCommingsoon = styled.p`
  /* position: absolute; */
  /* top: -15px; */
  font-size: 32px;
  color: red;
  background-color: rgba(255, 255, 255, .7);
  padding: 0 5px;
  margin-top: 40px;
  font-weight: bold;  
`;

export const BannerAppListItemIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-top: 3px;
  margin-right: 5px;
`;
export const BannerAppSpecialText = styled.span`
  font-weight: 500;
`;

export const BannerAppSpecialBrand = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: 600;

`;


export const HomeCopyText = styled.p`
  font-size: 18px;

  line-height: 24px;
  max-width: 500px;
  margin: 0 auto 20px;
  font-weight: 100;
  text-align: left
`;

export const BannerAppCopy = styled.div`
  padding: 70px 0
`;