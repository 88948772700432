import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop({ children }) {
  const { pathname } = useLocation()

  const onScroll = () => {
    window.scrollTo(0, 0)
  };
  
  useEffect(() => {
    onScroll()
  }, [pathname])

  useEffect(() => {
    window.addEventListener("beforeunload", onScroll);
    return () => window.removeEventListener("beforeunload", onScroll);
  }, [])

  return <>{children}</>
}

export default ScrollToTop