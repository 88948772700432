import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './es.json'
import pl from './pl.json'
import en from './en.json'
import pt from './pt.json'

i18n.use(initReactI18next).init({
  resources: {
    es: { translation: es },
    // pl: { translation: pl },
    // en: { translation: en },
    // pt: { translation: pt }
  },
  lng: 'es', // Default language
  fallbackLng: 'es', // Fallback language if translation is not found
  interpolation: {
    escapeValue: false
  },
  react:{
    bindI18n: 'languageChanged',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false //   <---- this will do the magic
  }
});

export default i18n;
