import React, { useState, useEffect,  } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Editor from "../Editor/Editor";
import PostForm from '../PostForm/PostForm'
import { generateDay } from "../../../../../utils/date";
import ImagesManager from "../../../../../components/ImagesManager/ImagesManager";
import FolderSelector from '../../../../../components/folderSelector/FolderSelector'
import { copyToClipboard } from '../../../../../utils/helpers'
import { deletePost } from '../../../../../utils/api/blog';
import PopUp from '../PopUp/PopUp'
import {AUTHORS, TYPE, VISIBILITY } from '../constants'

import bin from './resources/bin.png';

import './CreateEditPost.css'

const noop = ()=> {}
const CreateEditPost = ({
  postDetails={},
  handleUpdate = noop,
  isLoading,
  isSend, 
  setIsSend
}) => {
  const [postData, setPostData] = useState({})
  const [content, setContent] = useState('')
  const [images, setImages] = useState([])
  const [imageModal, setImageModal] = useState(null)
  const [descriptionModal, setDescriptionModal] = useState(null)
  const [newPositionIndex, setNewPositionIndex] = useState(0)
  const [imageDescription, setImageDescription] = useState('')
  const history = useHistory()
  const { id } = useParams()
  const isEdit = !!id

  useEffect(() => {
    if (Object.values(postDetails).length !== 0){
      setContent(postDetails.content || "");
      setPostData(postDetails);

      // TODO: update BD for have all the same structure
      if ((postDetails.images || []).length > 0) {
        const isNew = postDetails.images[0].url
        if (!isNew) {
          const imageNewFormat = postDetails.images.map(ele => ({
            url: ele,
            description: ''
          })) 
           setImages(imageNewFormat);
        } else {
         setImages(postDetails.images);
        }
      }
    }
  }, [postDetails._id]);

  // useEffect(() => {
  //   // TODO: save en local storage y eliminar cuando guarde
  // }, [postData, content]);

  const handleChange = name => event => {
    if (name.includes(".")) {
      const [prefix, sufix] = name.split(".")
      const existing_value = postData[prefix]
      const updated_value = {...existing_value, [sufix]: event.target.value}
      setPostData({...postData, [prefix]: updated_value})
    }
    else setPostData({ ...postData, [name]: event.target.value})
  }

  const handleCheckboxChange = name => event => {
    if (name.includes(".")) {
      const [prefix, sufix] = name.split(".")
      const existing_value = postData[prefix]
      const updated_value = {...existing_value, [sufix]: event.target.checked}
      setPostData({...postData, [prefix]: updated_value})
    }
    else setPostData({ ...postData, [name]: event.target.checked})
  }

  const onContentChange = (editorValue) => {
    // if (editorValue.includes('img')) {
    //   value = editorValue.replace('<p>', '<div>')
    //   value = value.replace('</p>', '</div>')
    // }


    // console.log(editorValue)
    setContent(editorValue)
  }
  
  const onFolderChange = (value) => { 
    setPostData({...postData, cloud_folder: value})
  }

  const onAddAllImgInEditor = () => {
    const listHTML = images.map((src) => {
      return `<div><img src="${src}" alt="" style="width: 30% ;display: block; margin-left: auto; margin-right: auto;" /></div>`
    }).join('')
    setContent(prevContent => `${prevContent}${listHTML}`)
  }
  
  const onSelectImage = (checked, value) => {
    if (!checked) {
      setImages(prevState => [
        ...prevState, 
        {
          description: '',
          url: value
        }
      ])
    } else {
      const position = images.findIndex(e => e.url === value);
      setImageModal({ value, index: position })
      setNewPositionIndex(position)
    }
  }
  
  
  const onNewImgPositionSave = () => {
    const currPos = parseInt(imageModal.index)
    const newPos = parseInt(newPositionIndex)
    console.log({currPos, newPos})
    const list = []
    images.forEach((item, idx) => {
      if (idx === newPos) {
        list.push(images[currPos])
        list.push(item)
      } else if (idx !== currPos) {
        list.push(item)
      }
    })
    setImages(list)
    setImageModal(null)
  }

  const removeImage = () => {
    setImages(prevState => prevState.filter(item => item.url !== imageModal.value))
    setImageModal(null)
  }
  
  const onDeleteHandler = (spotID) => {
    deletePost(spotID)
      .then(() => {
         history.push('/admin')
      })
  }
  
  const onSubmitHandler = () => {
    handleUpdate({ ...data, images })
  }
  
  const onDescriptionImageChange = () => {
    setImages(prevState => {
      return prevState.map(ele => {
        if (ele.url === descriptionModal.url) {
          return {
            ...ele,
            description: imageDescription
          }
        }
        return ele
      })
    })
    setDescriptionModal(null)
    setImageDescription('')
  }

  const data = {
    author: postData.author || AUTHORS.rodrigo,
    beginningContent: postData.beginningContent,
    // categories: postData.categories,
    content,
    country: postData.country,
    cloud_folder: postData.cloud_folder,
    excerpt: postData.excerpt,
    isMagazine: !!postData.isMagazine,
    modificationDate: generateDay(),
    mainImage: postData.mainImage,
    meta_title: postData.meta_title ? postData.meta_title : postData.title,
    meta_description: postData.meta_description ? postData.meta_description : postData.excerpt,
    publicationDate: postData.publicationDate ? postData.publicationDate : generateDay(),
    slug: postData.slug,
    smallMainImage: postData.smallMainImage,
    title: postData.title,
    type: postData.type || TYPE.history,
    visibility: postData.visibility || VISIBILITY.draft,
    isLocked: postData.isLocked || false,
    lang: postData.lang || 'es'
  };

  const saveOrUpdateText = isEdit ? "Editar" : "Guardar"
  const cloudFolder = postData.cloud_folder?.name || postData.cloud_folder

  return (
    <div className="EditPost">
      <div className="EditPost-inputs-container">
        <button 
          onClick={() => history.push('/admin?type=photo')}
          className="EditPost-btn"
        >
          Atrás
        </button>
        {isEdit && (
          <div onClick={() => onDeleteHandler(id)} to='/admin'>
            <div className='EditPost-Bin' 
              src={bin} 
              alt='bin'
            />
          </div>
        )}
        <PostForm 
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          postData={postData}
          isEdit={isEdit}
        />
        
        {isEdit ? (
          <p>Carpeta: {cloudFolder}</p>
        ) : (
          <FolderSelector
            folder={cloudFolder}
            setFolder={onFolderChange}
          />
        )}
        <ImagesManager
          cloud_folder={cloudFolder}
          images={images}
          setImages={(selectedImages) => setImages(selectedImages)}
          onSelectImage={onSelectImage}
          setDescriptionModal={setDescriptionModal}
        />
        
        
        {(data.type === TYPE.photo || data.isMagazine) && (
          <div className="Images-visualization-wrapper">
            <p className="Images-visualization-title">Visualización</p>
            <div className="Images-visualization-list-wrapper">
              {(images || []).map(({ url }) => (
                <div
                  key={url}
                  className="Images-visualization-image"
                  onClick={() => copyToClipboard(url)}
                >
                  <img src={url} />
                </div>
              ))}
            </div>
          </div>
        )}
        

      {data.type === TYPE.history && !data.isMagazine && (
        <>
          <button onClick={onAddAllImgInEditor}>
            Introducir todas images editor
          </button>
          <Editor 
            value={content}
            onChange={onContentChange}
          />
        </>
      )}
      </div>
        <div className="EditPost-button-wapper">
          <button
            className="EditPost-save-btn"
            onClick={() => history.push('/admin')}
            disabled={isLoading}
          >
            Volver
          </button>
          <button
            className="EditPost-save-btn"
            onClick={onSubmitHandler}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : saveOrUpdateText}
        </button>
        {isSend && <PopUp action='update' setIsSend={setIsSend} />}
      </div>
      {!!descriptionModal && (
        <div className="EditPost-modal-background">
          <div className="EditPost-modal">
            <div className="EditPost-modal-selector-wrapper">
              <label>Add description to image in position {descriptionModal.idx + 1}</label>
              <textarea
                style={{ width: '100%', minHeight: 80}}
                className="EditPost-modal-selector"
                type='text'
                placeholder='Description of the image (It will appear in the web)'
                value={imageDescription || descriptionModal.description}
                onChange={(evt) => {
                  setImageDescription(evt.target.value)
                }}
              />
              <button
                className="EditPost-save-btn"
                onClick={onDescriptionImageChange}
              >
                Edit
              </button>
            <button
              className="EditPost-save-btn"
              onClick={() => {
                  setDescriptionModal(null)
                  setImageDescription('')
                }}
            >
            cancelar
          </button>
            </div>
          </div>
        </div>
      )}
      {!!imageModal && (
        <div className="EditPost-modal-background">
          <div className="EditPost-modal">
            <div className="EditPost-modal-selector-wrapper">
              <label>Selecciona otra posisición</label>
              <select
                className="EditPost-modal-selector"
                type='select'
                placeholder='Selecciona otra posisición'
                value={newPositionIndex}
                onChange={(evt) => {
                  setNewPositionIndex(evt.target.value)
                }}
                SelectProps={{ MenuProps: {} }}
                margin='normal'
                variant='outlined'
              >
                {images.map((_, idx) => (
                  <option
                    key={idx}
                    value={idx}
                    disabled={idx === imageModal.index}
                  >
                    {idx + 1}
                  </option>
                )
              )}
              </select>
              <button
                className="EditPost-save-btn"
                onClick={onNewImgPositionSave}
              >
                Añadir
              </button>
            </div>
            <p>o deseleccionarlo pulsando "Quitar"</p>
            <button
              className="EditPost-save-btn"
              onClick={() => removeImage(data)}
            >
            Quitar
          </button>
            <button
              className="EditPost-save-btn"
              onClick={() => setImageModal(null)}
            >
            cancelar
          </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEditPost;