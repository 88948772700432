import React, { useState, useEffect } from 'react';
import { Link }     from 'react-router-dom'
import PostList     from '../components/blogManager/PostList/PostList'
import Button from '../../../components/button/Button'
import { getEmails } from '../../../utils/api/newsletter'

import {
  NewsletterListWrapper,
  NewsletterEmail,
  NewsletterListCardWrapper,
  NewsletterListButtonWrapper,
  NewsletterListNav,
  NewsletterListItemContent,
  NewsletterLink
} from './NewsletterList.styles'

const NewsletterList = () => {
  const [emails, setEmails] = useState([])
  const [otherEmails, setOtherEmails] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [checkboxStatus, setCheckboxStatus] = useState([])
  
  useEffect(() => {
    if (isLoading) return
    setLoading(true);
    (async () => await getEmails()
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error getting cloud images')
        }
        const activeEmails = []
        const otherStatusEmails = []
        data.map((item) => {
          if (item.status === 'active') {
            activeEmails.push(item)
          } else {
            otherStatusEmails.push(item)
          }
        })
        setEmails(activeEmails)

        setOtherEmails(otherStatusEmails)
        setCheckboxStatus(data.map(() => false))
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
    )()
  }, [])

  const onCheckHandler = (idx) => {
    setCheckboxStatus(prevState => {
      return prevState.map((ele, i) => {
        if (idx === i) return !ele
        return ele
      })
    })
  }

  if (isLoading) {
    return 'isLoading...'
  }

  const copyClipboard = (value) => {
    navigator.clipboard.writeText(value);
  }

  return (
    <NewsletterListWrapper>
      <NewsletterListNav>
        <Link to='/admin'>Go to blog manager</Link>
        <button onClick={() => setCheckboxStatus(prevState => prevState.map(() => false))}>Reset all</button>
      </NewsletterListNav>
      <NewsletterListCardWrapper>
        {emails.length === 0 ? 'No emails' : (
          emails.map((item, idx) => (
            <>
           
            <NewsletterListItemContent key={item._id} onClick={() => onCheckHandler(idx)}>
              <input type='checkbox' checked={checkboxStatus[idx]} />
              <NewsletterEmail>
                {item.email}
              </NewsletterEmail>
            </NewsletterListItemContent>
              <NewsletterLink onClick={() => copyClipboard(item.email)}>
                Dar de baja <a href={`https://emptyroadproject.com/newsletter/unsubscribe?email=${item.email}`}> aquí</a>
              </NewsletterLink>
            </>
          ))
        )}
      </NewsletterListCardWrapper>
      <NewsletterListCardWrapper>
        {otherEmails.length === 0 ? 'No emails' : (
          otherEmails.map((item, idx) => (
            <NewsletterListItemContent>
              <NewsletterEmail>
                {item.email}
              </NewsletterEmail>
              <NewsletterEmail>
                {item.status}
              </NewsletterEmail>
            </NewsletterListItemContent>
          ))
        )}
      </NewsletterListCardWrapper>
    </NewsletterListWrapper>
  );
}

export default NewsletterList;