import styled, { keyframes } from 'styled-components';

// Keyframe animation for shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const AdventuresListItem = styled.div`
  height: 510px;
  max-width: 33,3%;
  border-radius: 20px;
  overflow: hidden;
  transition: .3s;
  background: linear-gradient(to right, #f0f0f0 4%, #e0e0e0 25%, #f0f0f0 36%);
  background-size: 1000px 100%;
  animation: ${shimmer} 1.5s infinite;
`