import styled from 'styled-components';

export const BeyondTravelWrapper = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  padding: 130px 0 80px;
  background: #fff
  `

export const BeyondTravelBoxButton = styled.button`
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 7px;
  cursor: pointer
  
`
export const BeyondTravelBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  align-items: center;
  width: 400px;
  height: 400px;
  background: rgba(0,0,0, .1);
  cursor: pointer;
  &:hover {
    opacity: .8;
  };
  &:hover ${BeyondTravelBoxButton} {
    background: #808080
  }
  @media(max-width: 720px) {
    width: 300px;
    height: 300px;
  }
`

export const BeyondTravelBoxesWrapper = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 50px;
 @media(max-width: 720px) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}
`

export const BeyondTravelDescription = styled.p`
  font-family: 'Lora';
  font-size: 30px;
  margin: 0 auto 50px;
  padding: 0 5px;
  font-weight: 900;
  font-style: italic;
  @media(max-width: 480px) {
    font-size: 30px;
    width: 300px
  }
`

export const BeyondTravelBoxTextArea = styled.div`
  z-index: 1;
 
`

export const BeyondTravelBoxTitle = styled.p`
  font-size: 18px;
  font-weight: 800;
  z-index: 1;
  padding: 5px;
  color: #000;
  background: #fff
 
`

export const BeyondTravelBoxImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .5
`

export const BeyondTravelBoxButtonTextBold = styled.span`
  font-weight: 100;
`