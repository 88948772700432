import styled from 'styled-components';

export const CloudManagerViewWrapper = styled.div`
  position: relative;
  padding-top: 100px;
  min-height: 100vh;
  text-align: left;
  margin: 0 50px 200px;
`


export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 500px;
  overflow-y: auto;
  padding: 20px 0 30px;
  background-color: black;
  margin-top: 50px;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 170px;
  width: 170px;
  overflow: hidden;
  background-color: grey;
  margin: 5px;
`

export const DropAreaWrapper = styled.div`
  margin-bottom: 30px;
`

export const CloudManagerTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
`

export const DeleteIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 1px;
  color: #000;
  background-color: #fff;
  border: .5px solid #000;
  font-weight: 600;
  cursor: pointer;
  :hover {
    opacity: .8;
  }
  :active {
    background-color: red;
  }
  img {
    width: 100%;
  }
`


export const DescriptionModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.8);
  z-index: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  background: #fff;
  padding: 20px;
`

export const TextareaLabel = styled.p`
  font-size: 14px;  
  margin-bottom: 5px;
`
export const TextareaInput = styled.textarea`
  height: 300px;
  width: 400px;
`

export const DescriptionIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 2px 3px;
  cursor: pointer;
`

export const CopyIcon = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 2px 3px;
  cursor: pointer;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`