import styled from 'styled-components';

export const NewsletterListWrapper = styled.div`
  min-height: 100vh;
  text-align: left;
  margin: 0 50px;
`

export const NewsletterListNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  text-decoration: underline;
  &:hover {
    opacity: .8;
  }
`

export const NewsletterListCardWrapper = styled.div`
  margin-bottom: 50px
`

export const NewsletterListItemContent = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  :hover {
    opacity: .6
  }
`

export const NewsletterEmail = styled.p`
  font-weight: 600;
`

export const NewsletterLink = styled.p`
  font-weight: 300;
  font-size: 10px;
  margin-bottom: 15px;
  font-family: sans-serif;

    & a {
      color: #830390;
      text-decoration: underline;
    }
`

export const NewsletterListButtonWrapper = styled.div`
  margin-bottom: 30px
`
