import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  PhotographyArticuleWrapper,
  PhotographyArticuleBox,
  PhotoBlogViewDescripton,
  PhotographyArticuleImage,
  PhotographyButtonWrapper,
  PhotographyArticuleTitle,
  PhotographyText,
  PhotographyArticuleCountry
} from './PhotographyArticule.styles'

const PhotographyArticule = ({ adventure }) => {
  const {
    slug,
    title,
    country,
    smallMainImage,
    mainImage,
  } = adventure
  const { src, alt } = smallMainImage || mainImage
  const { t } = useTranslation()
  return (
    <Link to={`/photography/${slug}`}>
      <PhotographyArticuleWrapper>
        {src && (
          <PhotographyArticuleImage
            src={src}
            alt={alt}
          />
        )}
        <PhotographyArticuleBox>
          
          <div>
            <PhotographyArticuleTitle>
              {title}
            </PhotographyArticuleTitle>
            <PhotographyArticuleCountry>
              {country}
            </PhotographyArticuleCountry>
          </div>
        </PhotographyArticuleBox>
        <PhotographyButtonWrapper>
          <PhotographyText>
            {t('card.mirada.seeMore')}
          </PhotographyText>
        </PhotographyButtonWrapper>
      </PhotographyArticuleWrapper>
    </Link>
  );
};

export default PhotographyArticule;