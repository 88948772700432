import styled from 'styled-components';

export const NewsletterUnsubscribeWrapper = styled.div`
  margin-top: 100px;
  height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
`
export const LoginInputWrapper = styled.div`
  margin: 20px;
  width: 350px;
  height: 100px;
`

export const LoginInput = styled.input`
  padding: 30px 20px;
  width: 250px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 5px 5px 5px rgba(0,0,0, .2);
  border: 1px solid #333;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  &:focus {
    padding: 27px 17px;
    border: 3px solid orange
  }
`

export const NewsletterUnsubscribeTitle = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 30px;
`
export const NewsletterUnsubscribeText = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 10px 0;
`

export const NewsletterUnsubscribeInfoText = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
`

export const NewsletterUnsubscribeCancelButton = styled.button`
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #08f26e;
  border: 2px solid #07da63;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`

export const NewsletterUnsubscribeSubmitButton = styled.button`
  padding: 10px 20px;
  margin: 10px 0;
  border: 2px solid red;
  color: red;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`
export const NewsletterUnsubscribeInfoWrapper = styled.div`
  margin: 30px 0;
`

export const NewsletterUnsubscribeButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
`