import React, { useState } from 'react';
import apiUrl from '../../utils/apiUrl'
import outlook from '../../assests/images/outlook.png'
import gmail from '../../assests/images/gmail.png'
import logoErp from '../../assests/images/logo-erp.png'
import { useTranslation, Trans } from 'react-i18next';

import {
  NewsletterBoxWrapper,
  NewsletterBoxContent,
  NewsletterBoxContentRight,
  NewsletterBoxContentLeft,
  NewsletterBoxCloseIconWrapper,
  NewsletterBoxDescription,
  NewsletterBoxInput,
  NewsletterBoxButton,
  NewsletterBoxErrorHint,
  NewsletterBoxHint,
  TextBold,
  MailIcon,
  MailsWrapper,
  MailText,
  MailsItem,
  NewsletterBoxSuccessTitle,
  NewsletterBoxSuccessText,
  NewsletterBoxSuccessInfo,
  NewsletterBoxHintEmail,
  NewsletterBoxHintName,
  NewsletterBoxHintPassword,
  Text,
  NewsletterBoxImage,
  Title,
  TextErp,
  Underline,
  NewsletterBoxArea,
  LeadMagnetText
} from './NewsletterBox.styles'

const NewsletterBox = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessMessage, setIsSuccessMessage] = useState(false)

  const { t } = useTranslation()

  const onSubmit = () => {
    const validateEmail = email => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    const isEmailValid = validateEmail(email)

    if (!isEmailValid) {
      setError(t('newsletter.succeed.email'))
      return
    }

    setIsLoading(true)
    
    fetch(
      `${apiUrl}/api/web/newsletter/create`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      },
    )
    .then(res => res.json())
    .then(res => {
      if (res.status === 'OK') {
        setIsSuccessMessage(true)
        setEmail(email)
      } else {
        setError(res.message)
        console.error('Error. Newsletter: ', res.message)
      }
    })
      .catch(error => {
        setError(t('newsletter.error.tryAgain'))
        console.error('Error. Newsletter: ', error)
      })
    .finally(() => {
      setIsLoading(false)
    })
  }
  

  return (

        <NewsletterBoxContent>
            {isSuccessMessage ? (
              <>
                <NewsletterBoxSuccessTitle>{t('newsletter.succeed.title')}</NewsletterBoxSuccessTitle>
                <NewsletterBoxSuccessText>
                  <Trans i18nKey="newsletter.succeed.confirmMessage" components={{ bold: <b />, email }}/>
                </NewsletterBoxSuccessText>
                <MailsWrapper>
                  <MailsItem>
                    <a href='https://mail.google.com/mail/u/0/#inbox' target='_blank' rel='noopener noreferrer'>
                      <MailIcon src={gmail} alt='gmail icon' />
                      <MailText>{t('newsletter.succeed.open')} Gmail</MailText>
                    </a>
                  </MailsItem>
                  
                  <MailsItem>
                    <a href='https://outlook.live.com/mail/0/inbox' target='_blank' rel='noopener noreferrer'>
                      <MailIcon src={outlook} alt='outlook icon' />
                      <MailText>{t('newsletter.succeed.open')} Outlook</MailText>
                    </a>
                  </MailsItem>
                </MailsWrapper>
                <NewsletterBoxSuccessInfo>{t('newsletter.succeed.checkSpam')}</NewsletterBoxSuccessInfo>
              </>
            ) : (
              <>
                <Title>
                  <Trans i18nKey="newsletter.sendEmailAndName" components={{ special: <TextErp /> }}/>
                </Title>
                <Text>
                  <Trans i18nKey="newsletter.nameChar" components={{ special: <TextErp /> }}/>
                </Text>
                <Text>
                  {t('newsletter.exclusiveStories')}
                </Text>
                <Text>
                  {t('newsletter.exclusiveStories2')}
                </Text>
                <LeadMagnetText>
                  <Trans i18nKey="newsletter.leadMagnet" components={{ special: <Underline /> }}/>
                </LeadMagnetText>
                <form>
                  <NewsletterBoxInput placeholder={t('newsletter.email')} value={email} onChange={(evt) => {setEmail(evt.target.value)}} />
                  <NewsletterBoxHintEmail>{t('newsletter.emailHint')}</NewsletterBoxHintEmail>
                </form>
                
                {error && <NewsletterBoxErrorHint>{error}</NewsletterBoxErrorHint>}
                <NewsletterBoxButton onClick={onSubmit}>{isLoading ? t('newsletter.buttonTextSending') : t('newsletter.buttonText')}</NewsletterBoxButton>
                <NewsletterBoxImage src={logoErp} />
              </>
            )}
        </NewsletterBoxContent>
    
  ); 
}

export default NewsletterBox;