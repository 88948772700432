import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import  { validateForm } from '../../utils/validations'
import  apiUrl from '../../utils/apiUrl'

import  { 
  LoginWrapper,
  LoginInput,
  LoginInputWrapper,
  Title
} from './login.styles'

const DEFAULT_USER = { 
  email: '', 
  password: ''
}

const Login = () => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [user, setUser] = useState(DEFAULT_USER)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      history.push('/admin')
    }
  }, [])


  const onChange = (evt) => {
    const { target: { value, name } } = evt;

    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }))
  }

  const onSubmit = (evt) => {
    evt.preventDefault()
    const hasError = validateForm(user)
    if (hasError) {
      setErrors(hasError)
    }
    // 1 validar que los valores son validos
    // 2 parsearlos a string
    const validatedUser = user
    const basehost = apiUrl

    setIsLoading(true)

    fetch(
      `${basehost}/api/web/admin/login`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(validatedUser)
      },
    )
    .then(res => res.json())
    .then(res => {
      if (res.status === 'OK') {
        localStorage.setItem('token', res.token)
        localStorage.setItem('user', JSON.stringify(res.user))
        history.push('/admin')
      } else {
        setErrors(res.message)
        console.error('Error. dentro de login: ', res.message)
      }
    })
    .catch(error => console.error('Error. dentro de login: ', error))
    .finally(() => {
      setIsLoading(false)
      setUser(DEFAULT_USER)
    })
  }


  const { email, password } = user

  return (
    <LoginWrapper>
      <Title>Login (B)</Title>
      <form>
        <LoginInputWrapper>
          <LoginInput
            label='email'
            name='email'
            onChange={onChange}
            placeholder='Email'
            type='email'
            value={email}
          />
          {errors?.email && <span>{errors.email}</span>}
        </LoginInputWrapper>
        <LoginInputWrapper>
          <LoginInput
            label='password'
            name='password'
            onChange={onChange}
            placeholder='Password'
            type='password'
            value={password}
          />
          {errors?.password && <span>{errors.password}</span>}
        </LoginInputWrapper>
        <button
          type='button'
          onClick={onSubmit}
        > 
          {isLoading ? 'Loading...' : 'Log in'}
        </button>
      </form>
    </LoginWrapper>
  )
}

export default Login;