import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DropArea     from '../../../components/DropArea/DropArea'
import { getCloudImages, deleteCloudImage } from '../../../utils/api/blog'
import { copyToClipboard  } from '../../../utils/helpers'
import deleteIcon from './delete_icon.png'

import {
  CloudManagerViewWrapper,
  Wrapper,
  ImageWrapper,
  DropAreaWrapper,
  CloudManagerTitle,
  DeleteIcon,
  DescriptionModal,
  TextareaInput,
  DescriptionWrapper,
  TextareaLabel,
  DescriptionIcon,
  CloseIconWrapper,
  CopyIcon
} from './CloudManagerView.styles.js'

const CloudManagerView = () => {
  const [imagesCloud, setImagesCloud] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [description, setDescription] = useState('')
  const [showDescriptionModal, setShowDescriptionModal] = useState(false)
  
  const { folder } = useParams()
  
  useEffect(() => {
    if (isLoading) return
    setLoading(true);
    
    (async () => await getCloudImages(folder)
      .then(({ status, data }) => {
        if (status !== 'OK') {
          setLoading(false)
          console.error('Error getting cloud images')
        }
        setImagesCloud(data)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
    )()
  }, [])
  
  const onClickImage = (checked, value) => {
    if (!checked) {
      setSelectedImages(prevState => [
        ...prevState, 
        value
      ])
    } else {
      setSelectedImages(prevState => prevState
        .filter(
          item => item !== value
        )
      )
    }
  }
  
  const onDeleteHandler = async (imgToDelete) => {
    console.log('isDeleting', isDeleting)
    if (isDeleting) return
    setIsDeleting(true)
    const result = window.confirm("Are you sure you want to delete this item?");
    if (result) {
      // https://cdn-empty-road.s3.eu-west-1.amazonaws.com/pirineos/KL_069840-w.jpg
      const routeFile = imgToDelete.split(folder)[1]
      const fileName = routeFile.slice(1)
      deleteCloudImage(folder, fileName)
        .then(() => {
          setImagesCloud(prevState => {
            return prevState.filter(src => src !== imgToDelete)
          })
        })
        .finally(() => {
          setIsDeleting(false)
        })
    } else {
      setIsDeleting(false)
    }
    
  }
  
  const onChange = (evt) => {
    setDescription(evt.target.value)
  }
  
  return (
    <CloudManagerViewWrapper>
      <CloudManagerTitle>Folder: {folder}</CloudManagerTitle>
      <DropAreaWrapper>
        <DropArea folderName={folder} />
      </DropAreaWrapper>
      <Wrapper>
        {imagesCloud.map((src) => {
          const isSelected = selectedImages.includes(src)
          const isVideo = src.includes('video') || src.includes('.mov')
          return (
            <ImageWrapper
              // 
              // isSelected={isSelected}
              // onClick={() => onClickImage(isSelected, src)}
            >
              {
                isVideo 
                  ? <video width='100%' controls src={src} />
                  : <img style={{ width: '100%' }} src={src} />
              }
              <DeleteIcon onClick={() => onDeleteHandler(src)}>
                <img src={deleteIcon} alt='Delete icon' />
              </DeleteIcon>
              <DescriptionIcon onClick={() => setShowDescriptionModal(true)}>
                T
              </DescriptionIcon>
              <CopyIcon onClick={() => copyToClipboard(src)}>
                C
              </CopyIcon>
            </ImageWrapper>
          );
        })}
      </Wrapper>
      {showDescriptionModal && (
        <DescriptionModal >
          <CloseIconWrapper onClick={() => setShowDescriptionModal(false)}>
            <svg width="30px" height="30px" viewBox="0 0 24 24">
              <g id="Close_Circle">
                <path id="Vector" d="M9 9L11.9999 11.9999M11.9999 11.9999L14.9999 14.9999M11.9999 11.9999L9 14.9999M11.9999 11.9999L14.9999 9M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
          </CloseIconWrapper>
          <DescriptionWrapper>
            <TextareaLabel>Introduce descripción de la imagen</TextareaLabel>
            <TextareaInput
              onChange={onChange}
              value={description}
            />
          </DescriptionWrapper>
        </DescriptionModal>
      )}
    </CloudManagerViewWrapper>
  );
}

export default CloudManagerView;