import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const FooterWrapper = styled.div`
`;

export const FooterMainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 150px;
  padding: 50px 80px 80px;
  background-color: #263740;
  @media(max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FooterMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media(max-width: 430px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const FooterFragment = styled.div`
  position: relative;
  color: rgb(203, 44, 87);
  height: 10px;
  background-color: #006f72;
`;

export const FooterMenuLeft = styled.div`
  width: 100%;
  width: max-content;
`

export const FooterBrandName = styled.span`
 font-size: 1.6rem;
  font-family: 'Averia Serif Libre', sans-serif;
  color: #fff;
`

export const FooterMenuList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  @media(max-width: 430px) {
    justify-content: flex-start;
  }
`
export const FooterListItem = styled.li`
  margin: 0 10px;
  padding: 10px 0;
  cursor: pointer;
  transition: .3s;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: #fff;
    opacity: .8;
  }
  a {
    font-weight: 300;
    font-size: 14px;
    color: #fff !important;
  }
`

export const FooterBlog = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
`

export const FooterBlogBox = styled.div`
  width: 100%;
  height: 100%;
  cursor: default;
`

export const FooterSocialNetworksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  @media(max-width: 430px) {
    justify-content: flex-start;
  }
`

export const FooterMenuRight = styled.div`
  margin-bottom: 50px;
`

export const FooterSupport = styled.div`
`

export const FooterSupportText = styled.p`
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 200;
  margin-bottom: 10px;
  color: #fff;
`

export const BoldText = styled.span`
 font-weight: bold;
`

export const FooterPolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  a {
    font-size: .7rem;
    font-weight: 300;
    margin: 0 5px;
    color: #fff;
    white-space: nowrap;
  }
  margin-top: 10px;
  @media(min-width: 500px) {
    position: absolute;
    left: 15px;
    bottom: 15px;
    text-align: left;
    margin-top: 0;
  }
`

export const FooterInfoWrapper = styled.div`
  @media(min-width: 500px) {
    margin-bottom: 0;
    position: absolute;
    right: 15px;
    bottom: 15px;
    text-align: right;
  }
`
export const FooterInfo = styled.span`
  font-size: .60rem;
  font-weight: 200;
  color: #fff;
`

export const LogoImage = styled.img`
  width: 80px;
  display: block;
  margin: 0 auto 20px;
`
