import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import archImg from './arch.jpg';
import cirqaImg from './cirqa.jpg';
import {Link} from 'react-router-dom'

import  { 
  BeyondTravelWrapper,
  BeyondTravelBox,
  BeyondTravelBoxesWrapper,
  BeyondTravelDescription,
  BeyondTravelBoxTitle,
  BeyondTravelBoxImg,
  BeyondTravelBoxButton,
  BeyondTravelBoxTextArea,
  BeyondTravelBoxButtonTextBold
} from './BeyondTravel.styles'

const BeyondTravel = () => {
  const history = useHistory()
  const [controlVideo, setControlVideo] = useState('')

  const { t } = useTranslation()


  return (
    <BeyondTravelWrapper>
      <BeyondTravelDescription>
        Vamos Dos Pasos Más Allá del Viaje
      </BeyondTravelDescription>
      <BeyondTravelBoxesWrapper>
      <Link to='/mas-alla-del-viaje/hospitality'>
        <BeyondTravelBox>
          <BeyondTravelBoxTextArea>
            <BeyondTravelBoxTitle>
              ¿Eres HOTEL?
            </BeyondTravelBoxTitle>
            <BeyondTravelBoxButton>
              Click <BeyondTravelBoxButtonTextBold>aquí</BeyondTravelBoxButtonTextBold> 
            </BeyondTravelBoxButton>
          </BeyondTravelBoxTextArea>
          <BeyondTravelBoxImg src={cirqaImg} alt='Habitación de hotel' />
        </BeyondTravelBox>
      </Link>
      <Link to='/mas-alla-del-viaje/architecture'>
        <BeyondTravelBox>
          <BeyondTravelBoxTextArea>
            <BeyondTravelBoxTitle>
              ¿Eres ARQUITECTO?
            </BeyondTravelBoxTitle>
            <BeyondTravelBoxButton>
            Click <BeyondTravelBoxButtonTextBold>aquí</BeyondTravelBoxButtonTextBold> 
              
            </BeyondTravelBoxButton>
          </BeyondTravelBoxTextArea>
          <BeyondTravelBoxImg src={archImg} alt='Detalle arquitectónico' />
        </BeyondTravelBox>
      </Link>
      </BeyondTravelBoxesWrapper>
    </BeyondTravelWrapper>
  );
};

export default BeyondTravel;