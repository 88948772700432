import styled from 'styled-components';

export const CloudManagerListWrapper = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  text-align: left;
  margin: 0 50px;
`

export const CloudManagerListNav = styled.div`
  margin-bottom: 30px;
  text-decoration: underline;
  &:hover {
    opacity: .8;
  }
`

export const CloudManagerListCardWrapper = styled.div`
  display: flex;
  height: min-content;
  flex-wrap: wrap;
  gap: 20px;
`

export const CloudManagerListCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 200px;
  height: 100px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 600;
  &:hover {
    opacity: .8;
  }
`

export const CloudManagerListButtonWrapper = styled.div`
  margin-bottom: 30px
`
