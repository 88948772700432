import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import apiUrl from '../../utils/apiUrl'
import Button from '../button/Button'

import {
  FeedbackWrapper,
  FeedbackQuestion,
  FeedbackList,
  FeedbackItem,
  FeedbackTextarea,
  FeedbackCommentTitle,
  ButtonWrapper,
  FeedbackCommentSubtitle
} from './Feedback.styles'

const AdventuresView = ({ postId }) => {
  const [adventure, setAdventure] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [comment, setComment] = useState('');
  const [commentSend, setCommentSend] = useState(false);

  const { t } = useTranslation()

  const sendFeedback = (feedbackEmoji) => {
    if (feedback) return

    setIsLoading(true)

    fetch(`${apiUrl}/api/web/blog/feedback/${postId}`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ feedback_emoji: feedbackEmoji })
        },
      )
      .then(res => res.json())
      .then(result => {
        if (result.status !== 200) {
          console.error(result.error)
          return
        }
        setIsFeedbackSent(true)
        setFeedback(result.data)
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }

  const sendComment = () => {
    if (!comment) return 

    setIsLoading(true)
    const payload = { comment, feedback_id: feedback._id }

    fetch(`${apiUrl}/api/web/blog/feedback/comment/${postId}`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        },
      )
      .then(res => res.json())
      .then(result => {
        if (result.status !== 200) {
          console.error(result.error)
          return
        }

        setCommentSend(true)
       
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }

  if (commentSend) {
    return (
        <FeedbackWrapper>
            <FeedbackQuestion>{t('feedback.tankYou')}</FeedbackQuestion>
        </FeedbackWrapper>
    )
  }

  return (
    <FeedbackWrapper>
        {isLoading ? <FeedbackCommentTitle>{t('loading')}</FeedbackCommentTitle> : (
            <>
                <FeedbackQuestion>{t('feedback.title')}</FeedbackQuestion>
                <FeedbackList>
                    {(!isFeedbackSent || feedback.emoji === 1) && <FeedbackItem isSelected={feedback.emoji === 1} onClick={() => sendFeedback(1)}>🤩  {t('feedback.love')}</FeedbackItem>}
                    {(!isFeedbackSent || feedback.emoji === 2) && <FeedbackItem isSelected={feedback.emoji === 2} onClick={() => sendFeedback(2)}> 😊  {t('feedback.noBad')}</FeedbackItem>}
                    {(!isFeedbackSent || feedback.emoji === 3) && <FeedbackItem isSelected={feedback.emoji === 3} onClick={() => sendFeedback(3)}>🙄  {t('feedback.couldBeBetter')}</FeedbackItem>}
                </FeedbackList>
                {isFeedbackSent && (
                    <>
                        <FeedbackCommentTitle>{t('feedback.additionalComments')}</FeedbackCommentTitle>
                        <FeedbackCommentSubtitle>{t('feedback.weReadYou')}</FeedbackCommentSubtitle>
                        <FeedbackTextarea
                            placeholder={t('feedback.elaborate')}
                            onChange={(evt) => { setComment(evt.target.value) }}
                            value={comment}
                        />
                        <ButtonWrapper>
                            <Button 
                                width={"100%"}
                                onClick={sendComment}
                                text={t('feedback.send')}
                            />
                        </ButtonWrapper>
                    </>
                )}
            </>
        )}
    </FeedbackWrapper>
  );
};

export default AdventuresView;