import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const AdventureContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1500px;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 10px;
  margin: 50px auto 80px;
  @media(max-width: 670px) {
    margin: 0 15px;
  }
`

export const PhotoDescriptionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  background: rgba(255,255,255, .8);
  padding: 10px 10px 30px;
  max-height: 60%;
  right: 0;
  left: 0;
  transform: .3s;
  opacity: 0;
`
export const PhotoImage = styled.img`
  position: absolute;
  width: 100%;
  transition: .3s;
`

export const PhotoImageHidden = styled.img`
  width: 100%;
  opacity: 0
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  width:  ${({ isHorizontal }) => isHorizontal ? '61%' : '30%'};
  overflow: hidden;
   
  @media(max-width: 870px) {
    width: 100%;
    padding: 0;
  }

  &:hover ${PhotoImage} {
    width: 105%
  }
  &:hover ${PhotoDescriptionWrapper} {
  opacity: 1;
      transition: .3s;
  }
`

export const PhotoBlogViewDescripton = styled.p`
  color: #000;
  font-size: 13px;
  text-align: left;
  font-weight: 300;
`

export const ExpandIcon = styled.img`
  align-self: center;
  margin-bottom: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`

export const PhotoExpandedWrapper = styled.div`
  // overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0, .8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageExpandedWrapper = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
 ${({ isHorizontal }) => isHorizontal ? `height: 80%` : `height: 90%`};
  @media(max-width: 975px) {
    ${({ isHorizontal }) => isHorizontal && `height: auto; width: 80%`};
  };
  @media(max-width: 700px) {
    gap: 20px;
  };
  @media(max-height: 620px) {
    padding: 10px;
    height: 80%;
  };

  @media(max-width: 620px) {
    width: 70%;
    padding: 20px;
    height: auto;
  };
`

export const ImageExpanded = styled.img`
  height: 100%;
  @media(max-width: 975px) {
    ${({ isHorizontal }) => isHorizontal && `height: auto; width: 100%`};
  };
  @media(max-height: 620px) {
    height: 100%;
    width: auto;
    ${({ isHorizontal }) => isHorizontal && `height: auto; width: 100%`};
  };

  @media(max-width: 620px) {
    width: 100%;
    height: auto;
  }
`

export const PhotoExpandedCloseIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

export const ArrowLeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
  transform: rotate(180deg);
  @media(max-width: 620px) {
    display: none
  };
  &:hover {
    opacity: .9;
  }
`
export const ArrowRightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
  @media(max-width: 620px) {
    display: none
  };
  &:hover {
    opacity: .9;
  }
`

export const ArrowIcon = styled.img`
  height: 100%;
  width: 50px;
`

export const MaskButtonWrapper = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; 
  justify-content: center;
  align-items: center;
`
export const MaskButtonRight = styled.div`
  width: 50%;
  height: 100%
`
export const MaskButtonLeft = styled.div`
  width: 50%;
  height: 100%
`

export const MobileArrowLeftWrapper = styled.div`
  position: absolute;
  bottom: -10px;
  left: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  transform: rotate(180deg);
  @media(min-width: 620px) {
    display: none
  };
`
export const MobileArrowRightWrapper = styled.div`
  position: absolute;
  bottom: -10px;
  right: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  @media(min-width: 620px) {
    display: none
  };
`
