import styled, { keyframes } from 'styled-components';

export const PhotographyListWrapper = styled.div`
`
export const PhotographyListContent = styled.ul`
  margin: 70px auto;
  max-width: 1500px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 50px;
  @media(max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    max-width: 800px;
  }
  @media(max-width: 750px) {
    grid-template-columns: 1fr;
  }
  @media(max-width: 550px) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
`

export const PhotographyListItem = styled.li`
  max-width: 33,3%;
`

// Keyframe animation for shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const SkeletonLoading = styled.div`
  height: 100px;
  height: 500px;
  border-radius: 20px;
  transition: .3s;
  background: linear-gradient(to right, #f0f0f0 4%, #e0e0e0 25%, #f0f0f0 36%);
  background-size: 1000px 100%;
  animation: ${shimmer} 1.5s infinite;
`