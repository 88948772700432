import styled from 'styled-components';

export const AboutWrapper = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  padding: 130px 0 50px;
  background: #fff
  `
  export const AboutContent = styled.div`
  margin-top: 100px;
  padding: 60px 0 30px;
  text-align: left;
  background: #f6f5f2
`

export const AboutHeader = styled.div`
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 30px 0 20px;
`


export const AboutPerson = styled.div`
  border-radius: 5px;
  margin: 0 auto;
  max-width: 900px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
  margin-bottom: 80px;
`

export const AboutPersonVideo = styled.div`
  width: 410px;
  height: 720px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 10px 10px 15px rgba(0,0,0, .2);
`

export const AboutPersonImgWrapper = styled.div`
  max-width: 350px;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  overflow: hidden;
  overflow: hidden;
`
export const AboutPersonImg = styled.img`
  width: 100%;
  border-radius: 4px;
`

export const AboutPersonDescriptionWrapper = styled.div`
  display: flex;
  padding: 10px;
  max-width: 400px;
  justify-content: space-between;
  flex-direction: column;
`
export const AboutPersonDescription = styled.div`
`

export const AboutHeaderTitle = styled.h3`
   font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

export const AboutTitle = styled.p`
   font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 25px;
`
export const AboutBrandWrapper = styled.div`
  text-align: right;
  margin: 20px auto 0;
  max-width: 800px;
`
export const AboutBrand = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: 100;
  font-size: .75rem;
`

export const AboutHeaderText = styled.p`
  font-size: 1rem;
  font-family: 'Averia Serif Libre', sans-serif;
  color: #444;
  text-align: left;
  font-weight: 200;
  line-height: 1.4rem;
  margin: 0 auto 10px;
  max-width: 800px;
`
export const AboutHeaderTextBold = styled.span`
font-family: 'Averia Serif Libre', sans-serif;
font-weight: 900;

`

export const AboutText = styled.p`
  font-size: .9rem;
  color: #444;
  text-align: left;
  font-weight: 300;
  line-height: 1.3rem;
  max-width: 600px;
  margin-bottom: 10px;
  a {
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      opacity: .8;
    }
  }
`

export const AboutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`


export const AboutSocialNetworks = styled.div`
  display: flex;
  gap: 10px;
  align-items: end;
`
export const AboutSocialNetworksText = styled.div`
  font-size: .9rem;
  color: #444;
  font-weight: 300;
  margin-bottom: 5px;
`
export const SocialNetworksIcon = styled.img`
  width: 25px;
`