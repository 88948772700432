import styled from 'styled-components';

export const AdventuresListWrapper = styled.div`
`
export const AdventuresListContent = styled.ul`
  margin: 70px auto;
  max-width: 1500px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 50px;
  @media(max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    max-width: 800px;
  }
  @media(max-width: 750px) {
    grid-template-columns: 1fr;
  }
  @media(max-width: 550px) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
`

export const AdventuresListItem = styled.li`
  max-width: 33,3%;
  /* max-width: 390px; */
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  transition: .3s;
  &:hover {
    box-shadow: 0px 10px 10px rgba(0,0,0, .1);
  }
`