import styled from 'styled-components';

export const AboutWrapper = styled.div`
  min-height: 100vh;
  background: #fff
  `
  export const AboutContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #000;
  @media(min-width: 500px) {
    width: 350px;
    height: 350px;
  }
`


export const LogoImage = styled.img`
  opacity: .5;
  width: 100%;
`

export const LeadMagnetAudio = styled.audio`
 position: absolute;
 width: 250px;
 @media(min-width: 500px) {
  width: 290px;
}
`

export const AboutHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 130px;
  margin-bottom: 30px;
  padding: 100px 10px 30px;
  text-align: center;
`


export const AboutHeaderTitle = styled.h3`
   font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

export const AboutTitle = styled.p`
   font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 25px;
`
export const AboutBrandWrapper = styled.div`
  text-align: right;
  margin: 20px auto 0;
  max-width: 800px;
`
export const AboutBrand = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: 100;
  font-size: .75rem;
`

export const AboutHeaderText = styled.p`
  font-size: 1.2rem;
  font-family: 'Averia Serif Libre', sans-serif;
  color: #444;
  font-weight: 200;
  max-width: 800px;
`
export const AboutHeaderTextBold = styled.span`
font-family: 'Averia Serif Libre', sans-serif;
font-weight: 900;

`

export const AboutText = styled.p`
  font-size: .9rem;
  color: #444;
  text-align: left;
  font-weight: 300;
  line-height: 1.3rem;
  max-width: 600px;
  margin-bottom: 10px;
  a {
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      opacity: .8;
    }
  }
`

export const AboutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`


export const AboutSocialNetworks = styled.div`
  display: flex;
  gap: 10px;
  align-items: end;
`
export const AboutSocialNetworksText = styled.div`
  font-size: .9rem;
  color: #444;
  font-weight: 300;
  margin-bottom: 5px;
`
export const SocialNetworksIcon = styled.img`
  width: 25px;
`
