import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #cdcdcd;
  background-image: ${({ imageSrc }) => imageSrc && `url(${imageSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: ${({ height }) => height};
  width: 100%;
  // position: relative;

  z-index: -1
`;