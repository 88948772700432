import styled from 'styled-components';

export const PhotographyButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background: #fff;
  height: 0;
  right: 0;
  left: 0;
  transform: .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(0,0,0, .1), #000);
`

export const PhotographyArticuleTitle = styled.p`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 5px
`

export const PhotographyArticuleCountry = styled.p`
  color: #830390;
  font-size: .9rem;
  font-weight: 600;
  text-transform: uppercase;
`

export const PhotographyArticuleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition: 1s;
  overflow: hidden;
  &:hover {
    opacity: .8
  }
  &:hover ${PhotographyButtonWrapper} {
    height: 60%;
    transition: .3s;
    box-shadow: 0px 10px 10px rgba(0,0,0, .1);
  }
  &:hover ${PhotographyArticuleTitle} {
    color: #fff;
  }
`

export const PhotographyArticuleImage = styled.img`
  max-width: 600px;
  width: 100%;
`

export const PhotographyArticuleBox = styled.div`
  position: absolute;
  z-index: 2;
`

export const PhotographyText = styled.span`
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
`