import styled from 'styled-components';


const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const AdventureWrapper = styled.div`
  min-height: 100vh;
`;

export const AdventureArticule = styled.div`
  position: relative;
  margin: 50px 15px 0;
  min-height: 100vh;
`;

export const AdventureTitle = styled.h3`
  color: ${greyDark};
  font-size: 2.5rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Averia Serif Libre', sans-serif !important;
`

export const AdventureCountry = styled.h2`
  color: #830390;
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const AdventureContent = styled.div`
  overflow: hidden;
  min-height: 100vh;
  max-width: 900px;
  margin: 50px auto 80px;
  overflow: hidden;
  em {
    font-style: italic
  }

  strong {
    font-weight: bold
  }

  span, p {
    text-align: left !important;
    font-size: 16px !important;
    line-height: 28px;
    letter-spacing: 0.43px;
    font-weight: 100;
  }

  h1,
  h2 { 
    font-size: 32px !important;
    margin: 20px 0;
    font-style: italic;
    text-align: left !important;
    font-weight: 900;
    font-family: 'Averia Serif Libre', sans-serif !important;
  }

  h3 {
    font-size: 15px !important;
    margin: 35px 0 10px;
    text-transform: uppercase;
    font-weight: 900 !important;
  }

  div > img,
  p > img  {
    display: flex;
  }

  img { 
    margin: 30px auto !important;
    padding: 0 30px;
    max-height: 400px;
    width: auto;
    @media(max-width: 670px) {
      width: 100%;
      padding: 0;
      // height: auto;

      max-height: 100%;
    }
  }

  ul{
    padding:  20px 20px; 
  }
  ul p{
    margin-bottom: 10px;
  }
  p {
    text-align: justify !important;
  }

  h1, h2, h3, h4, h5, h6, p {
    background: transparent !important
  }

  a {
    color: #830390;
    text-decoration: underline;
    font-weight: 600
  }
`

export const ZenModeButtonWrapper = styled.div`
  position: fixed;
  // top: -30px;
  top: 50px;
  right: 20px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .6
  }
`

export const ZenModeButton = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  background-color: ${ ({ zenMode }) => zenMode ? 'black' : '#fff' };
  border: 1px solid #d4d4d4;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  cursor: pointer;
`

export const ZenModeImage = styled.img`
  width: 30px;
`

export const ZenModeButtonText = styled.span`
  font-size: 9px;
  margin-top: 2px;
  font-weight: 100
`

export const GoToTopButtonWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  left: 20px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: .6
  }
`

export const GoToTopButton = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  cursor: pointer;
`

export const GoToTopImage = styled.img`
  width: 20px
`