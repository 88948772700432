import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CreateEditPost from '../components/blogManager/CreateEditPost/CreateEditPost'
import apiUrl from '../../../utils/apiUrl'

const CreatePost = () => {
  const history = useHistory()

  const handleSavePost = data => {
    // const isAllFullfilled = Object.values(data).every(Boolean) // Delete content de data
    // if (!isAllFullfilled) return

    // Si no connection guardar en el local storage o en una cookie;
    const token = localStorage.getItem('token')
    return fetch(`${apiUrl}/api/blog/admin/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })
    .then(() => {
      history.push('/admin')
    })
    .catch(err => console.log(`Error when trying to save the post: ${err}`))
  }

  return (
    <div className='CreatePost'>
      <CreateEditPost handleUpdate={handleSavePost} />
    </div>
  );
};

export default CreatePost;
