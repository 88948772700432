import React from 'react';
import {Link} from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button
} from '@material-ui/core';
import bin from './images/bin.png'
import './PostCard.css'

const PostCard = ({ post, onDeleteHandler }) => {
  const tooLongText = !post.excerpt ? "" : ((post.excerpt.split(" ").length > 35) ? (post.excerpt.split(" ").splice(0, 35).join(' ') + '...') : post.excerpt)

  return (
    <Card className="Card">
      <CardActionArea className="PostCard-cardActionArea">
        <Link className="PostCard-link" to={`/admin/edit/${post._id}`}>
          <CardMedia
            className="PostCard-cardMedia"
            component="img"
            alt={(post.mainImage ? post.mainImage.alt : "") || ""}
            image={
              (post.mainImage ? post.mainImage.src : "-") || "-"} /* En este caso no se usa un string vacio porque salta el siguiente warning: Material-UI: either `children`, `image` or `src` property must be specified. */
            title={(post.mainImage ? post.mainImage.alt : "") || ""}
          />
          <CardContent className="PostCard-cardContent">
            <p className="PostCard-title">{post.title}</p>
            <p className="PostCard-excerpt">{tooLongText}</p>
          </CardContent>
        </Link>
      </CardActionArea>
      <CardActions className="PostCard-CardActions">
        <div>
        <Link to={`/admin/edit/${post._id}`} className="PostCard-link">
          <Button className="PostCard-btn" size="small">
            <p className="PostCard-btn-text">Editar</p>
          </Button>
          </Link>
          <span>visibility: {post.visibility}</span>
        </div>
        <img onClick={() => onDeleteHandler(post._id, post.title)} className="PostCard-bin" src={bin} alt="bin"/>
        <p className="PostCard-date">{post.creation_date}</p>
      </CardActions>
    </Card>
  );
};

export default PostCard;