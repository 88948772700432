import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import ScrollToTop from './hooks/ScrollToTop'
import i18n from './i18n/i18n'
import App from './App';
import './index.css';

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <ScrollToTop>
      <App />
      </ScrollToTop>
    </I18nextProvider>
  </BrowserRouter>
, document.getElementById('root'));

