import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const NewsletterBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto 80px;
  max-width: 500px;
  // height: 480px;
  margin-bottom: 80px;
  border-radius: 5px;
  background-color: #f6f5f2;
  padding: 30px 80px;
  box-shadow: 0px 10px 10px rgba(0,0,0, .1);

  @media(max-width: 500px) {
    padding: 30px 10px 40px;
  }
`

// export const NewsletterBoxArea = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin: 50px 30px 80px;
//   max-width: 500px;
//   width: 100%;
//   box-sizing: content-box;
//   border-radius: 5px;
//   background-color: #f6f5f2;
//   @media(max-width: 500px) {
//     padding: 30px 0 40px;
//   }
// `
export const NewsletterBoxDescription = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
  color: ${greyDark};
  padding: 30px 80px 40px;
  border: 1px solid red;

`
export const TextBold = styled.span`
  font-weight: 600;
`

export const NewsletterBoxInput = styled.input`
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;

`
export const NewsletterBoxHintPassword = styled.p`
  align-self: flex-start;
  font-weight: 300;
  margin-top: 5px;
  font-size: 11px;
  text-align: left;
  `

  export const NewsletterBoxHintEmail = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 11px;
  text-align: left;
`
  export const NewsletterBoxHintName = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 11px;
  text-align: left;
`

export const NewsletterBoxErrorHint = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: red;
`

// export const NewsletterBoxButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center; 
//   background-color: #000;
//   padding: 15px 10px;
//   border: none;
//   font-size: 14px;
//   font-weight: bold;
//   color: #fff;
//   cursor: pointer;
//   width: 100%;
//   margin-top: 20px;
// `


export const NewsletterBoxButton = styled.button`
padding: 15px 20px;
color: #fff;
width: max-content;
background: #830390;
border-radius: 3px;
margin: 30px auto 0;
cursor: pointer;
transition: .2s;
letter-spacing: .8px;
border: none;
font-weight: bold;
width: 100%;
&:hover {
  background: orange;
}

`

export const MailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 50px 0;
  @media(max-width: 720px) {
    margin: 30px 0;
  }
`
export const MailsItem = styled.div`
   display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
`

export const MailIcon = styled.img`
  width: 35px;
  margin-right: 8px;
  @media(max-width: 720px) {
    width: 25px;
  }
`

export const MailText = styled.span`
  font-size: 16px;
  color:  #830390;
  text-decoration: underline;
  white-space: nowrap;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`

export const NewsletterBoxSuccessTitle = styled.p`
  font-size: 32px;
  font-family: 'Averia Serif Libre', sans-serif;
  margin-bottom: 30px;
  @media(max-width: 720px) {
    font-size: 24px;
  }
`
export const NewsletterBoxSuccessText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`
export const NewsletterBoxSuccessInfo = styled.p`
  font-size: 14px;
  font-weight: 500;
@media(max-width: 720px) {
    font-size: 12px;
  }
`


export const NewsletterBoxImage = styled.img`
  width: 80px;
  margin: 40px auto 0
`

export const Text = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 300
`

export const LeadMagnetText = styled.span`
  font-size: 14px;
  display: block;
  margin: 30px auto 30px;
  line-height: 20px;
  max-width: 380px;
  font-weight: 600
`

export const Title = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
`

export const TextErp = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: bold;
  color: #830390
`

export const Underline = styled.span`
  font-weight: bold;
  font-weight: 800;
`