import styled from 'styled-components';

export const FeedbackWrapper = styled.div`
    background: #fff;
    padding: 50px 30px;
    max-width: 400px;
    margin: 100px auto 80px;
    border-radius: 5px;
`;

export const FeedbackQuestion = styled.p`
    font-weight: bold
`;

export const FeedbackList = styled.ul`
    margin: 30px 0 5px;
    text-align: left;
`;

export const FeedbackItem = styled.li`
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 3px 5px 10px rgba(0,0,0, .1);
    border: 1px solid transparent;
    
    ${({isSelected}) => !isSelected ? `
        cursor: pointer;
        &:hover {
            border: 1px solid #830390;
            box-shadow: 3px 5px 10px rgba(0,0,0, .2);
            transition: .3s;
        }` : `
            border: 1px solid #830390;
        `
    } 
`;

export const FeedbackCommentTitle = styled.p`
    margin-bottom: 10px;
    text-align: left;
    font-weight: bold
`;

export const FeedbackCommentSubtitle = styled.p`
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    color: #7d7d7d;
`;

export const FeedbackTextarea = styled.textarea`
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    resize: vertical;
    caret-color: #830390;
    caret-shape: block;
    margin-bottom: 30px;
    font-family: 'Open Sans';
    min-height: 150px;
    border: 1px solid  #c7c7c7;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border: 1px solid  #830390;
    }
`;

export const ButtonWrapper = styled.p`
   display: flex;
   justify-content: center;
`;