import React, { useState, useEffect } from 'react'
import './PopUp.css'

const PopUp = ({ action, setIsSend }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(true);
    const setIsSendToFalse = () => setTimeout(() => setIsSend(false), 1000);
    setTimeout(() => {
      setActive(false);
      setIsSendToFalse();
    }, 2000);
  }, [setIsSend]);

  const msg = action === "create" ? "Creado nuevo post" : "Post actualizado";

  return (
    <div className={`PopUp PopUp--${active ? "opened" : "closed"}`}>
      <p className="PopUp-msg">{msg}</p>
    </div>
  );
};
export default PopUp
