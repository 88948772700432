import React, { useState, useEffect } from 'react'
import { getCloudFolders } from '../../utils/api/blog'

import {
  Wrapper,
  ImageCheckboxWrapper,
  ImageWrapper, 
} from './folderSelector.style';

const FolderSelector = ({ folder, setFolder }) => {
  const [folders, setFolders] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (isLoading) return
    setLoading(true);
    
    (async () => await getCloudFolders(folder)
      .then(({ status, data }) => {
        if (status !== 'OK') {
          console.error('Error getting cloud images')
        }
        setFolders(data)
        setFolder(data[0])
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      }))()
  }, [])

  return (
    <>
      {isLoading ? 'Loading...' : (
        <div>
            <label>Carpeta</label>
            <select
              id='outlined-select-author'
              type='select'
              placeholder='Carpeta'
              value={folder}
              onChange={(evt) => {
                const { target: { value } } = evt
                setFolder(value)
              }}
              SelectProps={{ MenuProps: {} }}
              margin='normal'
              variant='outlined'
            >
              {folders.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
      )}
    </>
  );
};

export default FolderSelector;