import React from 'react'
import {
  ButtonContainer,
  ButtonMain,
  ButtonImageContainer,
  ButtonImage,
  ButtonText,
} from './ButtonStyles'

const Button = ({
  onClick= () => {},
  image_src = null,
  text = '',
  disable,
  isLoading,
  isInvert,
  color,
  width,
  ...props
}) => {
  let backgroundStyle = "orange";
  if (isInvert) {
    backgroundStyle = "transparent";
  } else if (color && color === "red") {
    backgroundStyle = "#ff0000";
  }

  return (
    <ButtonContainer
      backgroundStyle={backgroundStyle}
      width={width}
      onClick={() => !disable && onClick()}
      disable={disable}
      {...props}
    >
      <ButtonMain>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <>
            {image_src && (
              <ButtonImageContainer>
                <ButtonImage source={image_src} />
              </ButtonImageContainer>
            )}
            <ButtonText
              isInvert={isInvert}
              color={color}
            >
              {text}
            </ButtonText>
          </>
        )}
      </ButtonMain>
    </ButtonContainer>
  );
}

export default Button;