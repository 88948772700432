import React from 'react';
import { generateDay } from '../../../../../utils/date';
import {AUTHORS_OPTIONS, TYPE_OPTIONS, TYPE, VISIBILITY_OPTIONS, LANG_OPTIONS } from '../constants'

import {
  PostOptionsGroup,
  PostOptionsImage,
  PostOptionsInput,
  PostOptionsInputWrapper,
  PostOptionsLabel,
  PostOptionSelectItem,
  PostOptionsSelect,
  PostOptionsWrapper,
  PostOptionsSmallImage,
  PostOptionsImageWrapper
} from './PostForm.styles.js'

const PostOptions = ({ handleChange, handleCheckboxChange, postData, isEdit }) => {
  const {
    _id,
    author,
    beginningContent,
    country,
    excerpt,
    mainImage,
    meta_title,
    meta_description,
    publicationDate,
    slug,
    smallMainImage,
    title,
    type,
    visibility,
    lang,
    isMagazine
  } = postData;

  return (
    <PostOptionsWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Type (Selecciona el idioma)</PostOptionsLabel>
        <PostOptionsSelect
          type='select'
          id='outlined-select-type'
          select
          placeholder='Type'
          value={lang || LANG_OPTIONS[0]}
          onChange={handleChange('lang')}
          margin='normal'
          variant='outlined'
        >
          {LANG_OPTIONS.map(option => (
            <PostOptionSelectItem key={option} value={option}>
              {option}
            </PostOptionSelectItem>
          ))}
        </PostOptionsSelect>
      </PostOptionsInputWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Type (Selecciona el blog donde aparecerá)</PostOptionsLabel>
        <PostOptionsSelect
          type='select'
          id='outlined-select-type'
          select
          placeholder='Type'
          value={type || TYPE.history}
          onChange={handleChange('type')}
          margin='normal'
          variant='outlined'
        >
          {TYPE_OPTIONS.map(option => (
            <PostOptionSelectItem key={option} value={option}>
              {option}
            </PostOptionSelectItem>
          ))}
        </PostOptionsSelect>
      </PostOptionsInputWrapper>
      {(!type || type === TYPE.history) && (
        <PostOptionsInputWrapper>
          <PostOptionsLabel>¿Es Magazine?</PostOptionsLabel>
          <span><input type='checkbox' disabled={isEdit} checked={isMagazine}  onChange={handleCheckboxChange('isMagazine')} /></span>
        </PostOptionsInputWrapper>
      )}
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Meta título</PostOptionsLabel>
        <PostOptionsInput
          placeholder='Meta título'
          margin='normal'
          value={meta_title || ''}
          onChange={handleChange('meta_title')}
        />
      </PostOptionsInputWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Meta description</PostOptionsLabel>
        <PostOptionsInput
          placeholder='Meta description'
          margin='normal'
          value={meta_description || ''}
          onChange={handleChange('meta_description')}
        />
      </PostOptionsInputWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Slug</PostOptionsLabel>
        <PostOptionsInput
          placeholder='Slug (en minuscula, separado por guiones; ej, nuevo-episodio-en-ecuador)'
          margin='normal'
          value={slug || ''}
          onChange={handleChange('slug')}
        />
      </PostOptionsInputWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Título</PostOptionsLabel>
        <PostOptionsInput
          placeholder='Título'
          margin='normal'
          value={title || ''}
          onChange={handleChange('title')}
        />
      </PostOptionsInputWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Extracto</PostOptionsLabel>
        <PostOptionsInput
          placeholder='Extracto (Resumen del post)'
          margin='normal'
          value={excerpt || ''}
          onChange={handleChange('excerpt')}
        />
      </PostOptionsInputWrapper>
      {(!type || type === TYPE.history) && (
        <PostOptionsInputWrapper>
          <PostOptionsLabel>Inicio del contenido que aparece en la tarjeta del post seguido de 3 puntos (...)</PostOptionsLabel>
          <PostOptionsInput
            placeholder='Inicio del contenido que aparece en la tarjeta del post seguido de 3 puntos (...)'
            margin='normal'
            value={beginningContent || ''}
            onChange={handleChange('beginningContent')}
          />
        </PostOptionsInputWrapper>
      )}
      <PostOptionsGroup>
        <PostOptionsInputWrapper>
          <PostOptionsLabel>Visibilidad</PostOptionsLabel>
          <PostOptionsSelect
            type='select'
            id='outlined-select-visibility'
            select
            placeholder='Visibilidad'
            value={visibility || VISIBILITY_OPTIONS[0]}
            onChange={handleChange('visibility')}
            margin='normal'
            variant='outlined'
          >
            {VISIBILITY_OPTIONS.map(option => (
              <PostOptionSelectItem key={option} value={option}>
                {option}
              </PostOptionSelectItem>
            ))}
          </PostOptionsSelect>
        </PostOptionsInputWrapper>
        <PostOptionsInputWrapper>
          <PostOptionsLabel>Fecha de publicación</PostOptionsLabel>
          <PostOptionsInput
            id='publicationDate'
            margin='normal'
            placeholder='Fecha de publicación'
            type='datetime-local'
            value={publicationDate || generateDay()}
            onChange={handleChange('publicationDate')}
          />
        </PostOptionsInputWrapper>
      </PostOptionsGroup>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Pais</PostOptionsLabel>
        <PostOptionsInput
          id='country'
          margin='normal'
          placeholder='Pais'
          type='country'
          value={country || ''}
          onChange={handleChange('country')}
        />
      </PostOptionsInputWrapper>
      <PostOptionsInputWrapper>
        <PostOptionsLabel>Autor</PostOptionsLabel>
        <PostOptionsSelect
          id='outlined-select-author'
          type='select'
          placeholder='Autor'
          value={author || AUTHORS_OPTIONS[0]}
          onChange={handleChange('author')}
          SelectProps={{ MenuProps: {} }}
          margin='normal'
          variant='outlined'
        >
          {AUTHORS_OPTIONS.map(option => (
            <PostOptionSelectItem key={option} value={option}>
              {option}
            </PostOptionSelectItem>
          ))}
        </PostOptionsSelect>
      </PostOptionsInputWrapper>
      <PostOptionsGroup>
        <PostOptionsInputWrapper>
          <PostOptionsInputWrapper>
            <PostOptionsLabel>Pequeña imagen cabecera (src), 600 x auto</PostOptionsLabel>
            <PostOptionsInput
              placeholder='URL pequeña imagen principal 600 x auto'
              margin='normal'
              value={(smallMainImage?.src) || ''}
              onChange={handleChange('smallMainImage.src')}
            />
          </PostOptionsInputWrapper>
          <PostOptionsInputWrapper>
            <PostOptionsLabel>Pequeña imagen cabecera (alt)</PostOptionsLabel>
            <PostOptionsInput
              placeholder='Alt pequeña imagen principal'
              margin='normal'
              value={(smallMainImage?.alt) || ''}
              onChange={handleChange('smallMainImage.alt')}
            />
          </PostOptionsInputWrapper>
        </PostOptionsInputWrapper>
        <PostOptionsSmallImage 
          src={smallMainImage?.src}
          alt={smallMainImage?.alt}
        />
      </PostOptionsGroup>
      <PostOptionsGroup>
        <PostOptionsInputWrapper>
          <PostOptionsInputWrapper>
            <PostOptionsLabel>Grande imagen cabecera (src), 1920 x 1080</PostOptionsLabel>
            <PostOptionsInput
              placeholder='URL grande imagen principal 1920 x 1080'
              margin='normal'
              value={(mainImage?.src) || ''}
              onChange={handleChange('mainImage.src')}
            />
          </PostOptionsInputWrapper>
          <PostOptionsInputWrapper>
            <PostOptionsLabel>Grande imagen cabecera (alt)</PostOptionsLabel>
            <PostOptionsInput
              placeholder='Alt grande imagen principal'
              margin='normal'
              value={(mainImage?.alt) || ''}
              onChange={handleChange('mainImage.alt')}
            />
          </PostOptionsInputWrapper>
        </PostOptionsInputWrapper>
          <PostOptionsImageWrapper>
            <PostOptionsImage 
              src={mainImage?.src}
              alt={mainImage?.alt}
            />
          </PostOptionsImageWrapper>
      </PostOptionsGroup>
    </PostOptionsWrapper>
  );
};

export default PostOptions;