import styled from 'styled-components';

const greyDark = '#444'
const grey = '#7d7d7d'

export const NavMobileWrapper = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  overflow: hidden;
  transition: opacity, visibility 1s cubic-bezier(.645,.045,.355,1);
  ${({ isOpen }) => isOpen && `transition-delay: .52s`};
  z-index: 9999;
  background-image: url('https://cdn-empty-road.s3.eu-west-1.amazonaws.com/pirineos/kl_07156.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* @media(min-width: 1000px) {
    display: none
  } */
`

export const NavMobileBox = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: 30px 30px;
  width: 100%;
  max-width: 400px;
  margin: 0 10px 100px;
  border-radius: 4px;
`


export const NavMobileList = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
`

export const NavMobileListItemIcon = styled.img`
  display: none;
  width: 24px;
  height: 24px;
`

export const NavMobileListItem = styled.span`
  display: inline-block;
  margin: 0 10px;
  padding: 10px 0;
  text-decoration: underline;
  color: #830390 !important;
  cursor: pointer;
  transition: .3s;
    white-space: nowrap;
    font-size: 16px;
    color: #000;
    font-weight: bold;
    letter-spacing: 1.2px;
`

export const NavMobileListItemWrapper = styled.li`
  width: max-content;
  margin-bottom: 10px;
  &:hover ${NavMobileListItemIcon} {
    display: block;
  }
  &:hover ${NavMobileListItem}{
    color: ${grey};
  }
`

export const NavMobileListItemLi = styled.li`
  display: flex;
  align-items: center;
`

export const NavMobileCloseWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`

export const MenuNavButton = styled.li`
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #830390;
  color: #fff;
  padding: 10px 20px;
  font-size: 12px;
  color: red !important ;
  border-radius: 50px;

  /* margin-left: 10px; */
  cursor: pointer;
  :hover {
    opacity: .8;
  }
  a {
    color: #fff
  }
`

export const NavMobileListItemSpecial = styled.span`
  font-size: 12px;
`

export const NavBarWelcome = styled.p`
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
`

export const NavBarSpecialText = styled.span`
  font-weight: 700;
  font-size: 16px;
  font-family: 'Averia Serif Libre', sans-serif;
`

export const NavBarAppWrapper = styled.span`
  margin-top: 30px;
`