import React        from 'react';
import { Link }     from 'react-router-dom'
import PostList     from '../components/blogManager/PostList/PostList'
import Button     from '../../../components/button/Button'
import {
  BlogManagerWrapper,
  BlogManagerButton,
  BlogManagerButtonWrapper,
  BlogManagerNav
} from './BlogManager.styles.js'

const BlogManager = () => (
  <BlogManagerWrapper>
    <BlogManagerNav>
      <Link to='/'>Go to Home</Link>
      <Link to='/admin/cloud'>Go to Cloud manager</Link>
    </BlogManagerNav>
    <BlogManagerButtonWrapper type='button'>
      <Link to='/admin/create' style={{ width: '100' }}>
        <BlogManagerButton type='button'>
          + nuevo post
        </BlogManagerButton>
      </Link>
    </BlogManagerButtonWrapper>
    <BlogManagerNav>
      <Link to='/admin/newsletter'>Ir a Emails</Link>
    </BlogManagerNav>
    <PostList />
  </BlogManagerWrapper>
);

export default BlogManager;