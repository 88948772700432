import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BeyondTravelInput from '../../components/BeyondTravelInput/BeyondTravelInput'
import esteban from './images/esteban.jpg'
import ferrer from './images/ferrer.jpg'
import daniela from './images/daniela.jpg'
import martin from './images/marti.jpg'

import  { 
  ArchitectureWrapper,
  ArchitectureDescription,
  ArchitectureTitle,
  ArchitectureDescriptionItalic,
  ArchitectureDescriptionBold,
  ArchitectureDescriptionArea,
  ArchitectureDescriptionUl,
  ArchitectureDescriptionLi,
  ArchitectureInfonArea,
  ArchitectureDescriptionBoldBlock,
  ArchitectureDescriptionBlock,
  ArchitectureHotelItem,
  ArchitectureHotelImg,
  ArchitectureHotelWrapper,
  ArchitectureSelectionTitle,
  ArchitectureSelectionTitleBold,
  ArchitectureSelectionText,
  ArchitectureContactWrapper,
  ArchitectureContactText,
  ArchitectureContactTextBold,
  ArchitectureContactTextBg
} from './Architecture.styles'

const Architecture = () => {

  const { t } = useTranslation()

  return (
    <ArchitectureWrapper>
      <ArchitectureTitle>
        ¿Cómo se trabaja la arquitectura en otros lugares?
      </ArchitectureTitle>
      <ArchitectureDescription>
          Como arquitecto imagina <ArchitectureDescriptionBold>viajar por el mundo descubriendo la arquitectura local</ArchitectureDescriptionBold> de la mano de otros arquitectos locales.
      </ArchitectureDescription>
      <ArchitectureDescriptionArea>
      <ArchitectureDescription>
        <ArchitectureDescriptionItalic>
          ¿Eso hacéis vosotros?
        </ArchitectureDescriptionItalic>
      </ArchitectureDescription>
      </ArchitectureDescriptionArea>
      <ArchitectureDescription>
        Exacto, contactamos con arquitectos en cada país que visitamos para <ArchitectureDescriptionBold>fotografiar obras terminadas o en construcción</ArchitectureDescriptionBold>.
      </ArchitectureDescription>
      <ArchitectureDescription>
        Con el fin de <ArchitectureDescriptionBold>crear un porfolio de fotografía de arquitectura</ArchitectureDescriptionBold> y <ArchitectureDescriptionBold>aprender de otros profesionales</ArchitectureDescriptionBold>.
      </ArchitectureDescription>
        <ArchitectureContactWrapper>
          <ArchitectureContactText>
          <ArchitectureContactTextBg>
          Aquí te dejamos nuestro correo de contacto: <ArchitectureContactTextBold>contact@emptyroadproject.com</ArchitectureContactTextBold>
          </ArchitectureContactTextBg>
          </ArchitectureContactText>
        </ArchitectureContactWrapper>
      <ArchitectureSelectionTitle>
        <ArchitectureSelectionTitleBold>Selección de Estudios de Arquitectura </ArchitectureSelectionTitleBold> que ya han participado en el proyecto. 
      </ArchitectureSelectionTitle>
      {/* <ArchitectureSelectionText>
        <ArchitectureSelectionTitleBold>Click</ArchitectureSelectionTitleBold> en cada uno para abrir PDF del porfolio. 
      </ArchitectureSelectionText> */}
      <ArchitectureHotelWrapper>
        {/* <a href="https://erp-Architecture.s3.eu-west-1.amazonaws.com/ERP-Revista-Andean-Atemporal.pdf" target="_blank"> */}
          <ArchitectureHotelItem>
            <ArchitectureHotelImg src={esteban} />
          </ArchitectureHotelItem>
        {/* </a> */}
        {/* <a href="https://erp-Architecture.s3.eu-west-1.amazonaws.com/ERP-Revista-Cirqa.pdf" target="_blank"> */}
          <ArchitectureHotelItem>
            <ArchitectureHotelImg src={martin} />
          </ArchitectureHotelItem>
        {/* </a> */}
        {/* <a href="https://erp-Architecture.s3.eu-west-1.amazonaws.com/ERP-Revista-CruzDelVado.pdf" target="_blank"> */}
          <ArchitectureHotelItem>
            <ArchitectureHotelImg src={daniela} />
          </ArchitectureHotelItem>
        {/* </a> */}
        {/* <a href="https://erp-Architecture.s3.eu-west-1.amazonaws.com/ERP-Revista-Andean-Fausto.pdf" target="_blank"> */}
          <ArchitectureHotelItem>
            <ArchitectureHotelImg src={ferrer} />
          </ArchitectureHotelItem>
        {/* </a> */}
        {/* <a href="https://erp-Architecture.s3.eu-west-1.amazonaws.com/ERP-Revista-Andean-Fausto.pdf" target="_blank"> */}
          {/* <ArchitectureHotelItem>
            <ArchitectureHotelImg src={ferrer} />
          </ArchitectureHotelItem> */}
        {/* </a> */}
      </ArchitectureHotelWrapper>
      <ArchitectureContactWrapper>
          <ArchitectureContactText>
          <ArchitectureContactTextBg>
            De nuevo, para cualquier cosa: <ArchitectureContactTextBold>contact@emptyroadproject.com</ArchitectureContactTextBold>
          </ArchitectureContactTextBg>
          </ArchitectureContactText>
        </ArchitectureContactWrapper>
    </ArchitectureWrapper>
  );
};

export default Architecture;