import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import Button from '../../components/button/Button'
import { blogName, photo } from "../../constants";
import  apiUrl from '../../utils/apiUrl'

import  { 
  LoginWrapper,
  LoginInput,
  LoginInputWrapper,
  NewsletterUnsubscribeTitle,
  NewsletterUnsubscribeText,
  NewsletterUnsubscribeWrapper,
  NewsletterUnsubscribeSubmitButton,
  NewsletterUnsubscribeCancelButton,
  NewsletterUnsubscribeInfoWrapper,
  NewsletterUnsubscribeInfoText,
  NewsletterUnsubscribeButtonWrapper
} from './NewsletterUnsubscribe.styles'

const NewsletterUnsubscribe = () => {
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasArchived, setHasArchived] = useState(false)
  const [hasSubscribeAgain, setHasSubscribeAgain] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const email = location.search.split('email=')[1]

  const onUnsubcribehandle = async () => {
    if (!email) {
      setHasError(true)
      return
    }

    setHasError(false)
    setIsLoading(true)
    
    await fetch(
      `${apiUrl}/api/web/newsletter/unsubscribe`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      },
    )
    .then(res => res.json())
    .then(res => {
      console.log('res', res)
      if (res.status === 'OK') {
        setHasArchived(true)
      } else {
        console.log(res.message)
        setHasError(true)
        console.error('Error. Newsletter Confirm: ', res.message)
      }
    })
      .catch(error => {
        setHasError(true)
        console.error('Error. Newsletter Confirm: ', error)
      })
    .finally(() => {
      setIsLoading(false)
    })
  }
  
  const onSubcribeAgainhandle = async () => {
    if (!email) {
      setHasError(true)
      return
    }
      
    setHasError(false)
    setIsLoading(true)
    
    await fetch(
      `${apiUrl}/api/web/newsletter/confirm`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      },
    )
    .then(res => res.json())
    .then(res => {
      console.log('res', res)
      if (res.status === 'OK') {
        setHasSubscribeAgain(true)
      } else {
        console.log(res.message)
        setHasError(true)
        console.error('Error. Newsletter Confirm: ', res.message)
      }
    })
      .catch(error => {
        setHasError(true)
        console.error('Error. Newsletter Confirm: ', error)
      })
    .finally(() => {
      setIsLoading(false)
    })
  }
  
  if (hasSubscribeAgain) {
    return (
      <>
        <NewsletterUnsubscribeTitle>¡Suscripción activada con éxito!</NewsletterUnsubscribeTitle> 
        <NewsletterUnsubscribeInfoText>Échale un vistazo a nuestro viaje otra vez:</NewsletterUnsubscribeInfoText>
        <NewsletterUnsubscribeButtonWrapper>
          <Button 
              width={"300px"}
              onClick={() => history.push('/adventures')}
              text={`Ir a ${blogName}`}
          />
          <Button 
            width={"300px"}
            onClick={() => history.push('/photography')}
            text={`Ir a ${photo}`}
          />
        </NewsletterUnsubscribeButtonWrapper>
      </>
    )
  }
  
  if (hasArchived) {
    return (
      <>
        <NewsletterUnsubscribeInfoWrapper>
          <NewsletterUnsubscribeInfoText>REALMENTE, es una lásima que te vayas 💔</NewsletterUnsubscribeInfoText>
          <NewsletterUnsubscribeInfoText>Nos veremos por el mundo 🌎.</NewsletterUnsubscribeInfoText>
        </NewsletterUnsubscribeInfoWrapper>
          <NewsletterUnsubscribeCancelButton type='button'
            onClick={onSubcribeAgainhandle}
          > 
            {isLoading ? 'Loading...' : 'Quiero volver a Subscribirme'}
        </NewsletterUnsubscribeCancelButton>
      </>
    )
  }
  
  if (isLoading && !hasArchived) {
    return (
      <NewsletterUnsubscribeWrapper>
        <NewsletterUnsubscribeInfoText>Loading...</NewsletterUnsubscribeInfoText>
      </NewsletterUnsubscribeWrapper>
    )
  }


  return (
    <NewsletterUnsubscribeWrapper>
      <NewsletterUnsubscribeTitle>Unsubscribe</NewsletterUnsubscribeTitle>
    <NewsletterUnsubscribeInfoWrapper>
      <NewsletterUnsubscribeInfoText>Es una LÁSTIMA que te vayas 😢</NewsletterUnsubscribeInfoText>
      <NewsletterUnsubscribeInfoText>Pero, bueno, esperamos verte en la próxima aventura.</NewsletterUnsubscribeInfoText>
    </NewsletterUnsubscribeInfoWrapper>
      <NewsletterUnsubscribeText>Si cambias de opinión, puedes clicar aquí</NewsletterUnsubscribeText>
        <NewsletterUnsubscribeCancelButton type='button'
          onClick={() => history.push('/')}
        > 
          Quiero seguir con vosotros
        </NewsletterUnsubscribeCancelButton>
        <NewsletterUnsubscribeText>Si aún, quieres desvincularte del proyecto</NewsletterUnsubscribeText>
        <NewsletterUnsubscribeSubmitButton type='button' onClick={onUnsubcribehandle}> 
          {isLoading ? 'Loading...' : 'Por el momento, prefiero no recibir vuestras aventuras'}
        </NewsletterUnsubscribeSubmitButton>
    </NewsletterUnsubscribeWrapper>
  )
}

export default NewsletterUnsubscribe;