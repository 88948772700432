import styled from 'styled-components';

export const EditorWrapper = styled.div`
  margin-top: 80px;
  /* overflow: hidden;
  max-width: 950px;
  margin: 0 auto 50px; */

  #tinymce {
    image {

      border: 3px solid red
    }
  }
`

export const Hint = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`
