import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import PostCard from '../PostCard/PostCard';
import apiUrl from '../../../../../utils/apiUrl'
import { deletePost } from '../../../../../utils/api/blog';
import { photo } from '../../../../../constants';
import DropArea from '../../../../../components/DropArea/DropArea'
import './PostList.css'

const PostList = () => {
  const [posts, setPosts] = useState([])
  const [historyPost, setHistoryPost] = useState([])
  const [photoPost, setPhotoPost] = useState([])
  
  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');

  const [tab, setTab] = useState(type === 'photo' ? 2 : 1)


  useEffect(() => {
    const token = localStorage.getItem('token')
    fetch(`${apiUrl}/api/blog/admin/list`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(({ status, data }) => {
        console.log(status, data)
        if (status !== 200) {
          console.error('Error getting post list')
        }

        const historyPostData = []
        const photoPostData = []
        data.forEach((post) => {
          if (post.type === 'photo') {
            photoPostData.push(post)
          } else {
            historyPostData.push(post)
          }
        })
        
        setHistoryPost(historyPostData)
        setPhotoPost(photoPostData)
      })
      .catch(err => console.error(err));
  }, [])
  
  const onDeleteHandler = (spotID, title) => {
    const isConfirm = window.confirm(`Seguro quieres eliminar ${title}`);
    if (isConfirm) {
      deletePost(spotID)
        .then(() => {
          if (tab === 1) {
            setHistoryPost(prevState => {
              return prevState.filter(p => p._id !== spotID)
            })
          } else {
            setPhotoPost(prevState => {
              return prevState.filter(p => p._id !== spotID)
            })
          }
        })
    }
  }

  const renderPostList = (list) => {
    return list.map(post => (
      <PostCard 
        key={post._id} 
        post={post} 
        onDeleteHandler={onDeleteHandler}
      />
    ));
  }
  
 
  
  return (
    <div className='PostListWrapper'>
      <div className='PostListButtonWrapper'>
        <div className={`PostListButton ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>
          <span className='PostListTitle'>Historias y reflexiones</span>
        </div>
        <div className={`PostListButton ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>
          <span className='PostListTitle'>{photo}</span>
        </div>
      </div>
      {tab === 1 ? (
        <>
          <div className='PostList'>
            {posts ? renderPostList(historyPost) : <p>No existen elementos en la base de datos</p>}  
          </div>
        </>
      ) : (
        <>
    
          <div className='PostList'>
            {posts ? renderPostList(photoPost) : <p>No existen elementos en la base de datos</p>}  
          </div>
        </>
      )}
    </div>
  );
};

export default PostList;