import React from 'react';

import Header from '../../../components/header/Header';
import PhotographyList from '../../../components/photography/photographyList/PhotographyList';
import { photo } from '../../../constants';
import { useTranslation } from 'react-i18next';

import {
  AdventureWrapper,
  PhotographListWrapper,
  PhotographListTitle,
  PhotographListSubtitle
} from './PhotoBlogList.style'

import mountains from '../../../assests/images/peru-mountains.jpg';

const Photography = () => {
  const { t } = useTranslation()
  return (
    <AdventureWrapper>
      <Header imageSrc={mountains} isLessHeigh />
      <PhotographListWrapper>
        <PhotographListTitle>
          {photo}
        </PhotographListTitle>
        <PhotographListSubtitle>{t('home.blog.miradaDescription')}</PhotographListSubtitle>
        <PhotographyList />
      </PhotographListWrapper>
    </AdventureWrapper>
  );
};

export default Photography;