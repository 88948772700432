import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom'
import CreateEditPost from '../components/blogManager/CreateEditPost/CreateEditPost';
import apiUrl from '../../../utils/apiUrl'

import {
  EditPostButton,
  EditPostWrapper,
} from './EditPost.styles.js'

const UpdatePost = () => {
  const history = useHistory()
  const { id } = useParams()
  const [post, setPost] = useState({})
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const token = localStorage.getItem('token')

  useEffect(() => {
    let isCurrent = true 
    fetch(`${apiUrl}/api/blog/admin/view/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    )
      .then(res => res.json())
      .then(result => isCurrent && setPost(result.data))
      .catch(err => console.error(err))
    return ()=> isCurrent = false
  }, [id])

  const handleUpdate = data => {
    setIsLoading(true)
    // const isAllFullfilled = Object.values(data).every(Boolean)
    // if (!isAllFullfilled) return

    return fetch(`${apiUrl}/api/blog/admin/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        setIsSend(true)
      })
      .catch(err => console.log(`Error when trying to update the post: ${err}`))
      .finally((err) => {
        setIsLoading(false)
      });
  };

  return (
    <EditPostWrapper>
      <CreateEditPost 
        postDetails={post} 
        handleUpdate={handleUpdate}
        isLoading={isLoading}
        isSend={isSend}
        setIsSend={setIsSend}
      />
    </EditPostWrapper>
  );
};

export default UpdatePost;

/* TODO: */
/* Cuargar en local storage y al logout logout del local storage */
/* Al salir decir que vas a eliminar si quieres guardar */