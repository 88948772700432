import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import expand_icon from "./resources/expand_icon.png";
import arrowIcon from "./resources/arrow.png";

import {
  AdventureContent,
  ImageWrapper,
  PhotoBlogViewDescripton,
  PhotoDescriptionWrapper,
  ExpandIcon,
  PhotoImage,
  PhotoExpandedWrapper,
  ImageExpandedWrapper,
  ImageExpanded,
  PhotoExpandedCloseIconWrapper,
  ArrowLeftWrapper,
  ArrowRightWrapper,
  MobileArrowLeftWrapper,
  MobileArrowRightWrapper,
  ArrowIcon,
  MaskButtonWrapper,
  MaskButtonLeft,
  MaskButtonRight,
  PhotoImageHidden
} from './PhotoRender.style'

const defaultImgExpanded = { srcUrl: '', idx: 0 }

const PhotoRender = ({ images }) => {
  const [imgExpanded, setImgExpanded] = useState(defaultImgExpanded);
  
  useEffect(() => {
    const className = 'modal-open';
    imgExpanded.srcUrl
      ? document.body.classList.add(className)
      : document.body.classList.remove(className);
  }, [imgExpanded])

  const isExpandedImgHorizontal = imgExpanded.srcUrl.includes('-w.')

  const setPreviewImage = () => {
    const previewIdx = imgExpanded.idx - 1

    if (previewIdx >= 0) {
      const previewFound = images.find((_, idx) => idx === previewIdx)
      setImgExpanded({
        srcUrl: previewFound.url, 
        idx: previewIdx
      })
    }

  }

  const setNextImage = () => {
    const nextIdx = imgExpanded.idx + 1

    if (nextIdx < (images.length)) {
      const nextFound = images.find((_, idx) => {
        return idx === nextIdx
      })
  
      setImgExpanded({
        srcUrl: nextFound.url, 
        idx: nextIdx
      })
    }

  }

  return (
    <>
    <AdventureContent>
    {(images || []).map((item, idx) => {
        const { url, description } = item
        const srcUrl = url || item 
        const isHorizontal = srcUrl.includes('-w.') // horizontal images

        return (
        <ImageWrapper isHorizontal={isHorizontal} onClick={() => setImgExpanded({ srcUrl, idx })}>
            {<PhotoImage src={srcUrl} alt='image viaje' />}
            {/* Para mantener las dimensiones de la imagen al renderizar */}
            <PhotoImageHidden src={srcUrl} alt='imagen viaje oculta' /> 
            
            {description && (
            <PhotoDescriptionWrapper>
                <ExpandIcon src={expand_icon} alt='expand icon' title='Expandir' />
                <PhotoBlogViewDescripton>
                {description}
                </PhotoBlogViewDescripton>
            </PhotoDescriptionWrapper>
            )}
        </ImageWrapper>
        )
    })}
    </AdventureContent>
    {imgExpanded.srcUrl && (
        <PhotoExpandedWrapper>
          <PhotoExpandedCloseIconWrapper onClick={() => setImgExpanded(defaultImgExpanded)}>
            <svg width="30px" height="30px" viewBox="0 0 24 24">
              <g id="Close_Circle">
                <path id="Vector" d="M9 9L11.9999 11.9999M11.9999 11.9999L14.9999 14.9999M11.9999 11.9999L9 14.9999M11.9999 11.9999L14.9999 9M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
          </PhotoExpandedCloseIconWrapper>

          <MaskButtonWrapper>
            <MaskButtonLeft onClick={setPreviewImage} />
            <MaskButtonRight onClick={setNextImage} />
          </MaskButtonWrapper>

          <ImageExpandedWrapper isHorizontal={isExpandedImgHorizontal} >

              <MobileArrowLeftWrapper>
                {imgExpanded.idx > 0 && <ArrowIcon src={arrowIcon} alt='arrow icon' title='Ir a izquierda'/>}
              </MobileArrowLeftWrapper>
              {<ArrowLeftWrapper onClick={setPreviewImage}>
                {imgExpanded.idx > 0 && (
                  <ArrowIcon src={arrowIcon} alt='arrow icon' title='Ir a izquierda'/>
                )}
              </ArrowLeftWrapper>}

              <ImageExpanded src={imgExpanded.srcUrl} alt='' isHorizontal={isExpandedImgHorizontal} />
              
              <MobileArrowRightWrapper>
                {imgExpanded.idx < (images.length - 1) && <ArrowIcon src={arrowIcon} alt='arrow icon' title='Ir a derecha'/>}
              </MobileArrowRightWrapper>
              {<ArrowRightWrapper onClick={setNextImage}>
                {imgExpanded.idx < (images.length - 1) && (
                    <ArrowIcon src={arrowIcon} alt='arrow icon' title='Ir a derecha'/>
                )}
              </ArrowRightWrapper>}

          </ImageExpandedWrapper>
        </PhotoExpandedWrapper>
      )}
    </>
             
  );
};

export default PhotoRender;