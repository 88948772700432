import styled from 'styled-components';
import newsletterImage from './resources/newsletter_img.jpg'
const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const ModalWrapper = styled.div`
  height: 100vh;
  background: rgba(0,0,0, .8);
  z-index: 999;
  display: flex;
  justify-content: center;
  overflow: auto;
  text-align: left
`
export const ModalContent = styled.div`
  position: relative;
  min-height: 650px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
	@media(min-width: 720px) {
    display: flex;
  }
`
export const ModalContentLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 45%;
  background-color: ${greyLight};
  background-image: url(${newsletterImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  @media(max-width: 720px) {
    height: 20%;
    width: 100%;
  }
`
export const ModalContentRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 30px;
  padding-left: 50px;
  box-sizing: border-box;
  @media(max-width: 720px) {
    margin-top: 0px;
    width: 100%;
    padding-left: 30px;
  }
`

export const ModalFormTitle = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const SpecialText = styled.span`
  color: #830390;
  font-family: 'Averia Serif Libre', sans-serif;
`

export const ModalFormWrapper = styled.div`
  margin-top: 20px
`
export const ModalCloseIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
export const ModalTitle = styled.p`
  font-size: 38px;
  font-weight: lighter;
`
export const Underline = styled.span`
  font-weight: bold;
  font-weight: 600;
`

export const Colored = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  color: #830390;
`

export const ModalTitleSpecial = styled.p`
  color: #830390;
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 44px;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: 30px;
`
export const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 22px;
  color: ${greyDark};
`
export const TextBold = styled.span`
  font-weight: 600;
`

export const ModalInput = styled.input`
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;

 /* width: 300px; */

`
export const ModalHintName = styled.p`
  align-self: flex-start;
  font-weight: 300;
  margin-top: 5px;
  font-size: 11px;
  `
  export const ModalHintEmail = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 11px;
`

export const ModalErrorHint = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: red;
`

export const ModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; 
  background: #830390;
  padding: 15px 10px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  width: 100%;
  margin-top: 50px;
  &:hover {
    background: orange;
  }
`

export const MailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin: 50px 0;
  @media(max-width: 720px) {
    margin: 30px 0;
  }
`
export const MailsItem = styled.div`
   display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
`

export const MailIcon = styled.img`
  width: 35px;
  margin-right: 8px;
  @media(max-width: 720px) {
    width: 25px;
  }
`

export const MailText = styled.span`
  font-size: 16px;
  color:  #830390;
  text-decoration: underline;
  white-space: nowrap;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`

export const ModalSuccessTitle = styled.p`
  font-size: 32px;
  font-family: 'Averia Serif Libre', sans-serif;
  margin-bottom: 30px;
  @media(max-width: 720px) {
    font-size: 24px;
  }
`
export const ModalSuccessText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  @media(max-width: 720px) {
    font-size: 14px;
  }
`
export const ModalSuccessInfo = styled.p`
  font-size: 14px;
  font-weight: 500;
@media(max-width: 720px) {
    font-size: 12px;
  }
`