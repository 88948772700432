import styled from 'styled-components'

export const ImagesManagerWrapper = styled.div`
  margin-top: 40px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 350px;
  overflow-y: auto;
  padding: 50px 0 50px;
  background-color: black;
`

export const ImageBoxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 170px;
  overflow: hidden;
  background-color: grey;
  cursor: pointer;
  margin: 5px;
  border: 1px solid;
  border-color: ${({ isSelected }) =>  isSelected ? '#2a9df4' : '#000'};
`

export const ImageWrapper = styled.div`
  height: 170px;
  width: 170px;
  overflow: hidden;
  background-color: grey;
  :hover img {
    ${({ isSelected }) =>  !isSelected ? 'opacity: .8' : null}
  }
  img {
    ${({ isSelected }) =>  isSelected ? 'opacity: .3' : null}
  }
`

export const ImagePositionNumWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #000;
  color: #fff;
  border: 3px solid #08f26e;
  font-weight: 600;
`

export const ActionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
`

export const ImageCopy = styled.div`
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 1px;
  color: #000;
  background-color: #fff;
  border: .5px solid #000;
  font-weight: 600;
  :hover {
    opacity: .8;
  }
  :active {
    background-color: #08f26e;
  }
  img {
    width: 100%;
  }
`

export const ImageEdit = styled.div`
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 1px;
  color: #000;
  ${({ disable }) => disable && `cursor: default;`}
  ${({ hasDescription }) => hasDescription ? `background-color: #08f26e;` : `background-color: #fff;`}
  border: .5px solid #000;
  font-weight: 600;
  ${({ disable }) => disable && `cursor: default;`}
  ${({ disable }) => disable && `opacity: .6;`}
  :hover {
    ${({ disable }) => !disable && `opacity: .8;`}
  }
  :active {
    ${({ disable }) => !disable && `background-color: #08f26e;`}
  }
  img {
    width: 100%;
  }
`

export const SelectedInfo = styled.div`
  margin: 10px;
  font-size: 14px;
  font-weight: lighter;
  text-align: left;
`

export const EmptyStateWrapper = styled.div`
  margin: 10px;
`

export const EmptyStateMessage = styled.p`
  margin: 10px;
  font-size: 16px;
  font-weight: lighter;
  color: #fff;
`

export const EmptyStateButton = styled.button`
  margin-top: 15px;
  height: 40px;
  cursor: pointer;
`