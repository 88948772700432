import React, { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import 'tinymce';
import 'tinymce/themes/silver'; // was changed from modern to silver

// Import skin
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

// Import plugins
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/code';
import 'tinymce/plugins/media';

import { EditorWrapper, Hint } from './Editor.styles';

const noop = () => {};

export default ({ value = '', onChange = noop }) => {

  // useEffect(() => {
  //   // aria-label=Insert/edit image
  //   const addImgBtn = document.querySelector('.tox-tbtn')
  //   console.log(addImgBtn)
  // }, [])

  return (
    <EditorWrapper>
      <Hint>Tamaño de imagen máx width: 950px</Hint>
      <Hint>2 imagenes en la misma row width: 950px</Hint>
      <Editor
        className='Editor'
        value={value}
        init={{
          height: '90vh',
          plugins:
            'advlist autolink link image lists charmap print preview code media',
          toolbar:
            'undo redo | styleselect | bold italic underline | link image media | alignleft aligncenter alignright | code'
        }}
        onEditorChange={onChange}
      />
    </EditorWrapper>
  )
};


/* TODO: fix p > image in order to semantic */