export const validate = (name, value) => {
  
  if (!value) {
    return  'No value'
  }
  return null
}

export const validateForm = (form) => {
  let result = null
  Object
    .entries(form)
    .map(
      ([key, value]) => {
        const error = validate(key, value)
        if (error && !result) {
          if (!result) {
            result = {}
          }
          result[key] = error
        }
      }
    )

  return result
}