import apiUrl from '../apiUrl'

const BASE_PATH = '/api/admin/newsletter'

export const getEmails = async () => {
  const token = localStorage.getItem('token')
  return await fetch(`${apiUrl}${BASE_PATH}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
}
