import styled from 'styled-components';

export const HospitalityWrapper = styled.div`
  padding-top: 100px;
  min-height: 100vh;
  padding: 130px 10px 50px;
  background: #fff
  `

export const HospitalityTitle = styled.p`
  font-family: 'Lora';
  font-size: 24px;
  margin-bottom: 50px;
  font-weight: 900;
  font-style: italic
`

export const HospitalityDescription = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 20px;
`

export const HospitalitySelectionTitle = styled.p`
  margin: 0 auto 30px;
  font-size: 24px;
  line-height: 33px;
  font-weight: 100;
  font-family: "Josefin Sans" !important;
`

export const HospitalitySelectionText = styled.p`
  margin-bottom: 50px;
  font-size: 20px !important;
  line-height: 33px;
  font-weight: 100;
  font-family: "Josefin Sans" !important;
`

export const HospitalitySelectionTitleBold = styled.span`
  font-weight: 300;
  font-size: 26px;
  font-family: "Josefin Sans" !important;
`

export const HospitalityDescriptionBold = styled.span`
  font-weight: 700;
`

export const HospitalityDescriptionBlock = styled.span`
  display: block
`

export const HospitalityDescriptionItalic = styled.span`
  font-style: italic;
  font-weight: 500;
  font-size: 1.2rem;
`

export const HospitalityDescriptionArea = styled.div`
  margin: 50px 0
`

export const HospitalityInfonArea = styled.div`
  text-align: left;
  margin: 100px 50px 50px
`

export const HospitalityDescriptionUl = styled.ul`
  padding-left: 15px
`
export const HospitalityDescriptionLi = styled.li`
 
`
export const HospitalityHotelItem = styled.div`
 width: 350px;
 height: 350px;
 background: rgba(0,0,0, .1);
 border: 1px solid rgba(0,0,0, .0);
 cursor: pointer;
 transition: .3s;
 :hover {
  opacity: .8;
  box-shadow: 1px 1px 20px 0px rgba(0,0,0, .3);
 }
`
export const HospitalityHotelImg = styled.img`
  width: 100%;
  height: 100%;
 
`

export const HospitalityHotelWrapper = styled.div`
 display: flex;
 justify-content: center;
 gap: 10px;
 flex-wrap: wrap;
 margin: 0 10px 150px
 
`

export const HospitalityContactWrapper = styled.div`
  margin: 100px auto 130px;
`;
export const HospitalityContactText = styled.p`
  font-size: 16px;
  font-weight: 300;
  z-index: 1;
  color: #fff;
  line-height: 21px
`;

export const HospitalityContactTextBg = styled.span`
  background-color: #000;
  padding: 0 10px
`;

export const HospitalityContactTextBold = styled.span`
  
font-weight: 400;
`;