import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import MobileNavToggle from '../mobileNavToggle/MobileNavToggle'
// import menuStructure from '../../utils/menuStructure';
import { photo, blogName } from '../../constants'
import windRose from '../../assests/images/rose.png'

import {
  NavMobileWrapper,
  NavMobileList,
  NavMobileListItem,
  NavMobileCloseWrapper,
  NavMobileListItemWrapper,
  MenuNavButton,
  NavMobileListItemSpecial,
  NavMobileBox,
  NavBarWelcome,
  NavBarSpecialText,
  NavMobileListItemIcon,
  NavBarAppWrapper,
  NavMobileListItemLi
} from './NavMobile.styles';

const NavMobile = ({ isActive, setActive }) => {
  const { pathname } = useLocation()
  const isHome = pathname === '/'

  const { t } = useTranslation()

  return (
    <NavMobileWrapper isOpen={isActive}>
      <NavMobileCloseWrapper>
        <MobileNavToggle
          isActive={isActive}
          setActive={setActive}
        />
      </NavMobileCloseWrapper>
      <NavMobileBox >
        <NavBarWelcome>
          {t('navbar.mobile.hello')}
          {' '}
          <NavBarSpecialText>
            {t('navbar.mobile.traveller')}
          </NavBarSpecialText>,
        </NavBarWelcome>
        <NavBarWelcome>{t('navbar.mobile.discover')}</NavBarWelcome>
        <NavMobileList>
          <NavMobileListItemWrapper>
            <Link onClick={() => setActive(false)} to='/adventures'>
              <NavMobileListItemLi>
                  <NavMobileListItemIcon src={windRose}></NavMobileListItemIcon>
                  <NavMobileListItem>
                    {blogName}
                  </NavMobileListItem>
              </NavMobileListItemLi>
            </Link>
          </NavMobileListItemWrapper>
          <NavMobileListItemWrapper>
            <Link style={{ width: 'maxContent'}} onClick={() => setActive(false)} to='/photography'>
          <NavMobileListItemLi>
              <NavMobileListItemIcon src={windRose}></NavMobileListItemIcon>
              <NavMobileListItem>
                {photo}
              </NavMobileListItem>
          </NavMobileListItemLi>
            </Link>
          </NavMobileListItemWrapper>
          <NavMobileListItemWrapper>
            <Link style={{ width: 'maxContent'}} onClick={() => setActive(false)} to='/mas-alla-del-viaje'>
          <NavMobileListItemLi>
              <NavMobileListItemIcon src={windRose}></NavMobileListItemIcon>
              <NavMobileListItem>
                Más allá del viaje
              </NavMobileListItem>
          </NavMobileListItemLi>
            </Link>
          </NavMobileListItemWrapper>
            <NavMobileListItemWrapper>
          <Link style={{ width: 'maxContent'}} onClick={() => setActive(false)} to='/about'>
            <NavMobileListItemLi>
              <NavMobileListItemIcon src={windRose}></NavMobileListItemIcon>
                <NavMobileListItem>
                  {t('navBar.item.us')}
                </NavMobileListItem>
            </NavMobileListItemLi>
          </Link>
            </NavMobileListItemWrapper>
          {/* {isHome && (
          <NavBarAppWrapper>
            <NavBarWelcome>Mira, la app <NavBarSpecialText translate='no'>Hitchhikers Guide</NavBarSpecialText></NavBarWelcome>
            <NavBarWelcome> puede que te ayude en tu aventura:</NavBarWelcome>
            <NavMobileListItemWrapper>
              <a href='#app' onClick={() => setActive(false)}>
              <NavMobileListItem>
                <NavBarSpecialText translate='no'> Hitchhikers Guide</NavBarSpecialText> app
              </NavMobileListItem>
              </a>
            </NavMobileListItemWrapper>
          </NavBarAppWrapper>
          )} */}
        </NavMobileList>
    </NavMobileBox>
    </NavMobileWrapper>
  );
};

export default NavMobile;