import React, { useState } from 'react';
import Button from '../button/Button';
import {
  DropAreaWrapper,
  DropAreaContent,
  DropAreaBox,
  ButtonWrapper,
  DropAreaList,
  DropAreaItem,
  DropAreaInput,
  DropAreasWrapper
} from './DropArea.styles'

import apiUrl from '../../utils/apiUrl'

const DropArea = ({ folderName }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleDrop = (evt) => {
    evt.preventDefault();
    const droppedFiles = Array.from(evt.dataTransfer.files);
    const filesFormatted = droppedFiles.map(file => {
      const newFileName = file.name.trim().toLowerCase().replace(/_|\s/g, "-")
      const updatedFile = new File([file], newFileName, { type: file.type })
      return updatedFile
    })
    setFiles([...files, ...filesFormatted]);
  };

  const handleHorizontalFileDrop = (evt) => {
    evt.preventDefault();
    const droppedFiles = Array.from(evt.dataTransfer.files);
    const filesFormatted = droppedFiles.map(file => {
      const newFileName = file.name.trim().toLowerCase().replace(/_|\s/g, "-")
      const [name, extension] = newFileName.split('.')

      const updatedFile = new File([file], `${name}-w.${extension}`, { type: file.type })
      return updatedFile
    })
    setFiles([...files, ...filesFormatted]);
  };
  
  const handleFileChange = (evt) => {
    evt.preventDefault();
    const filesList = evt.target?.files 
    if (!filesList) return
    const filesListArray = Array.from(filesList);
    setFiles([...files, ...filesListArray]);
  };

  const handleHorizontalFiles = (evt) => {
    evt.preventDefault();
    const filesList = evt.target?.files 
    if (!filesList) return
    const filesListArray = Array.from(filesList);

    const list = filesListArray.map(file => {
      const [name, extension] = file.name.split('.')
      return new File([file], `${name}-w.${extension}`, { type: file.type })
    })

    setFiles(prevState => [...prevState, ...list]);
  }; 
  
  const handleFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  
  const onSubmit = () => {
    if (!folderName) return
    setIsLoading(true)
    const formData = new FormData()
    formData.append('folderName', folderName);
    files.forEach((file) => {
      formData.append('files', file);
    });

    const token = localStorage.getItem('token')
    return fetch(`${apiUrl}/api/blog/admin/clouds/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formData
    })
      .then(({ status }) => {
        if (status !== 'OK') {
          console.error('Error getting cloud images')
        }
        console.log('Listo!')
        // setIsSend(true)
      })
      .catch(err => console.log(`Error when trying to save the post: ${err}`))
      .finally(() => {
        setIsLoading(false)
        setFiles([])
      })

  };
  
  return (
    <>
      <DropAreasWrapper>
      <DropAreaWrapper>
        <p>Drop VERTICAL images HERE</p>
        <DropAreaContent>
          <DropAreaBox
            onDrop={handleDrop}
            onDragOver={(evt) => evt.preventDefault()}
          >
            <DropAreaInput type='file' multiple onChange={handleFileChange} />
          </DropAreaBox>
        </DropAreaContent>
      </DropAreaWrapper>
      <DropAreaWrapper>
        <p>Drop HORIZONAL images HERE (A "W" will be added in the name)</p>
        <DropAreaContent>
          <DropAreaBox
            onDrop={handleHorizontalFileDrop}
            onDragOver={(evt) => evt.preventDefault()}
          >
            <DropAreaInput type='file' multiple onChange={handleHorizontalFiles} />
          </DropAreaBox>
        </DropAreaContent>
      </DropAreaWrapper>
      {files.length > 0 && (
        <DropAreaList>
          {files.map((file, index) => (
            <DropAreaItem key={index}>
              {file.name}
              <button onClick={() => handleFileRemove(index)}>Remove</button>
            </DropAreaItem>
          ))}
        </DropAreaList>
      )}
      </DropAreasWrapper>




      <ButtonWrapper>
        <Button
          text={isLoading ? 'Uploading...' : 'Upload images in the cloud'}
          onClick={onSubmit}
          disable={isLoading || files.length === 0}
        />
      </ButtonWrapper>
    </>
  )
}

export default DropArea;