import styled from 'styled-components';

const greyDark = '#444'

export const MobileNavTogglerWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  margin-right: 15px;
  z-index: 9999;
`

export const MobileNavToggler = styled.div`
  position: relative;
  height: 26px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Inner = styled.div`
  height: 4px;
  width: 109px;
  transition-property: background;
  transform-origin: center center;
  transition: .5s cubic-bezier(.645,.045,.355,1);
  background-color: ${({ isActive, dark }) => (
    isActive ? 'transparent' : (dark ?  greyDark : '#fff' )
  )};
  ${({ isActive }) => isActive && `
    transform: rotate(-180deg) translate(0,0);
  `};

  &:before,
  &:after {
    content: ' ';
    background: ${({ dark }) => dark ?  greyDark : '#fff' };
    height: 4px;
    width: 100%;
    position: absolute;
    transform: rotate(0) translate(0,0);
    transform-origin: 50% 50%;
    left: 0;
  }
  &:before {
    top: ${({ isActive }) => isActive ? '8px' : 0};
    ${({ isActive }) => isActive && `
      transform: rotate(-39deg) translate(5px,-6px)
    `};
  }
  &:after {
    bottom: ${({ isActive }) => isActive ? '-9px' : 0};
    ${({ isActive }) => isActive && `
      transform: rotate(39deg) translate(-6px,-7px)
    `};
  }
`