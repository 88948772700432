export default [
  {
    title: 'navBar.item.aventura',
    path: '/adventures',
  },
  {
    title: 'navBar.item.mirada',
    path: '/photography',
  },
  {
    title: 'navBar.item.moreThanTravel',
    path: '/mas-alla-del-viaje',
  },
  {
    title: 'navBar.item.us',
    path: '/about'
  },
]