import styled from 'styled-components';

const greyDark = '#444'

export const AdventureWrapper = styled.div`
`;

export const AdventureListWrapper = styled.div`
  padding: 100px 80px;
  @media(max-width: 500px) {
    padding: 100px 20px;
  }
`;

export const AdventureListTitle = styled.p`
  color: ${greyDark};
  text-transform: uppercase;
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
`
export const AdventureListInfo = styled.p`
  font-size: .8rem;
  margin-bottom: 10px;
  font-weight: 300;
  margin-top: 10px;
  color: #444;
`

export const AdventureListSubtitle = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 100px
`