import styled from 'styled-components';

const grey = '#7d7d7d'
const greyDark = '#444'
const greyLight = '#c7c7c7'

export const NewsletterBoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: ${({isSuccessMessage}) => isSuccessMessage ? '250px' : '150px'};
  @media(max-width: 465px) {
    height: 280px;
  }
`

export const FormArea = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 10px;
`

export const NewsletterBoxDescription = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
  color: ${greyDark};
  padding: 30px 80px 40px;
`
export const TextBold = styled.span`
  font-weight: 600;
`

export const NewsletterBoxHintEmailWrapper = styled.div`
  position: absolute;
  max-width: 100%;
`

export const InputContainer = styled.div`
  width: 100%
  
  // width: 100%;
`

export const NewsletterBoxInput = styled.input`
  font-size: 16px;
  box-sizing: border-box;
  width: 310px;
  border-radius: 5px 0 0 5px;
  // border: 1px solid #000;
  border: none;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  padding: 16px 10px;
  outline: none;
  font-family: Cutive Mono;
  background: #fff;
  @media(max-width: 465px) {
    width: 200px;
    font-size: 12px;
  }
}
`
export const NewsletterBoxHintPassword = styled.p`
  align-self: flex-start;
  font-weight: 300;
  margin-top: 5px;
  font-size: 11px;
  text-align: left;
  `

  export const NewsletterBoxHintEmail = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  font-weight: 300;
  font-size: 10px;
  text-align: left;
  margin: 5px;
`
  export const NewsletterBoxExampleButton = styled.span`
  margin: 0 2px;
  cursor: pointer;
  text-decoration: underline
`

  export const NewsletterBoxExample = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  font-size: 10px;
  text-align: left;
  margin: 5px;
  font-weight: bold
`
  export const NewsletterBoxHintName = styled.p`
  margin-top: 5px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 11px;
  text-align: left;
`

export const NewsletterBoxErrorHint = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: red;
`


export const NewsletterBoxButton = styled.button`
padding: 16px 40px;
color: #fff;
width: max-content;
border-radius: 0 10px 10px 0;
cursor: pointer;
letter-spacing: .8px;
font-weight: bold;
font-size: 14px;
// border: 2px solid #000;
box-shadow: 0 0 5px rgba(0,0,0,.2);
border: none;
&:hover {
  background-color: orange;
  animation: none;
}
background: #830390;
animation: colorAnimation 2s infinite;
@media(max-width: 465px) {
  padding: 9px 20px;
  font-size: 12px;
};
@keyframes colorAnimation {
  0% {
    box-shadow: 0 0 15px #830390;
    background: #830390;
  }
  50% {
    box-shadow: 5px 0 20px orange;
    background: orange;
  }
  100% {
    box-shadow: 0 0 15px #830390;
    background: #830390;
  }
}

`

export const MailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 30px 0;
`
export const MailsItem = styled.div`
   display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`

export const MailIcon = styled.img`
  width: 25px;
  margin-right: 8px;
  @media(max-width: 720px) {
    width: 25px;
  }
`

export const MailText = styled.span`
  font-size: 14px;
  color:  #000;
  text-decoration: underline;
  white-space: nowrap;
  @media(max-width: 720px) {
    font-size: 12px;
  }
`

export const NewsletterBoxSuccessTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`
export const NewsletterBoxSuccessText = styled.p`
  font-size: 14px;
  font-weight: 300;
`
export const NewsletterBoxSuccessInfo = styled.p`
  font-size: 12px;
  font-weight: 500;
  @media(max-width: 720px) {
    font-size: 12px;
  }
`


export const NewsletterBoxImage = styled.img`
  width: 80px;
  margin: 40px auto 0
`

export const Text = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 300
`

export const Title = styled.p`
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 22px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
`

export const TextErp = styled.span`
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: bold;
  color: #830390
`

export const Underline = styled.span`
  font-weight: bold;
  font-weight: 800;
`

export const NewsletterBoxSuccessWrapper = styled.div`
  padding: 20px;
  background: rgba(255, 255, 255, .8);
  border-radius: 10px;
  margin: 0 10px;
  border: 1px solid rgb(0, 0, 0, .1);

`

export const NewsletterHeaderBoxFormWrapper = styled.div`
`;