import styled from 'styled-components';

const greyDark = '#444'

export const AdventureWrapper = styled.div`
`;

export const PhotographListWrapper = styled.div`
  padding: 100px 80px;
  @media(max-width: 500px) {
    padding: 100px 20px;
  }
`;

export const PhotographListTitle = styled.p`
  color: ${greyDark};
  text-transform: uppercase;
  font-family: 'Averia Serif Libre', sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
`

export const PhotographListSubtitle = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 100px
`